import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { InputText } from "primereact/inputtext";
import "./../styles/main.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import img1 from "../assets/11.png";
import img2 from "../assets/12.png";
import img3 from "../assets/13.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { toast } from "react-toastify";
import apiClient from "../Api/apiClient";
import { useDispatch } from "react-redux";
import { login } from "../redux/counterSlice";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
function RegisterMainModal(props) {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState("renter");
  let initialState = {
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    password: "",
    phone_no: "",
    user_type: selectedOption,
  };
  const [load, setLoad] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [logins, setLogin] = useState(initialState);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };
  const handleChange = (key, value) => {
    setLogin({ ...logins, [key]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(logins, "aaaas");
    const rental = {
      ...logins,
      username: logins.first_name + "" + logins.last_name,
    };
    console.log(rental, "llo");
    setLoad(true)
    let result;
    {
      logins?.user_type === "owner"
        ? (result = await apiClient.post(`/users/owner/signup`, logins))
        : (result = await apiClient.post(`/users/signup`, rental));
    }
    setLoad(false)
    if (result.error) return toast.error("Error");
    toast.success("Successfully Login");
    console.log(result);
    dispatch(
      login({ token: result?.data?.token,userId:result?.data?.userId, userType: result?.data?.user_type })
    );
    props.onHide();
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div  style={{ paddingInline: isSmallScreen?"20px":"70px" }}>
        <form onSubmit={handleSubmit}>
          <div className={`${isSmallScreen?"px-1":"px-5"} mt-2 py-3 d-flex justify-content-between align-items-center`}>
            <p
              className={`py-2 ${
                selectedOption === "renter" ? "Sign1" : "Sign"
              }`}
              style={{
                cursor: "pointer",
                fontSize:isSmallScreen && 12,
                borderBottom:
                  selectedOption === "renter" && "3px solid #246BBC",
              }}
              onClick={() => {
                handleChange("user_type", "renter");
                handleOptionClick("renter");
              }}
            >
              Sign up as Renter
            </p>
            <p
              className={`py-2 ${
                selectedOption === "owner" ? "Sign1" : "Sign"
              }`}
              style={{
                cursor: "pointer",
                fontSize:isSmallScreen && 12,
                borderBottom: selectedOption === "owner" && "3px solid #246BBC",
              }}
              onClick={() => {
                handleChange("user_type", "owner");
                handleOptionClick("owner");
              }}
            >
              Sign up as Owner
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-center flex-column">
            <div style={{ width: "80%" }} className="d-flex mt-3 flex-column">
              {/* <label for="exampleInputEmail1" class="form-label labels">Email</label> */}
              {/* value={value} onChange={(e) => setValue(e.target.value)}  */}
              <InputText
                value={logins.first_name}
                onChange={(e) => handleChange("first_name", e.target.value)}
                placeholder="First Name"
                className="py-3"
              />
            </div>
            <div style={{ width: "80%" }} className="d-flex mt-3 flex-column">
              {/* <label for="exampleInputEmail1" class="form-label labels">Email</label> */}
              {/* value={value} onChange={(e) => setValue(e.target.value)}  */}
              <InputText
                value={logins.last_name}
                onChange={(e) => handleChange("last_name", e.target.value)}
                placeholder="Last Name"
                className="py-3"
              />
            </div>
            <div style={{ width: "80%" }} className="d-flex mt-3 flex-column">
              {/* <label for="exampleInputEmail1" class="form-label labels">Email</label> */}
              {/* value={value} onChange={(e) => setValue(e.target.value)}  */}
              <InputText
                type="email"
                value={logins.email}
                onChange={(e) => handleChange("email", e.target.value)}
                placeholder="Email"
                className="py-3"
              />
            </div>
            <div style={{ width: "80%" }} className="mt-3">
              {/* <label for="exampleInputEmail1" class="form-label labels">Password</label> */}
              <div className="input-group">
                <input
                  value={logins.password}
                  onChange={(e) => handleChange("password", e.target.value)}
                  type={showPassword ? "text" : "password"}
                  className="form-control py-3"
                  style={{ fontWeight: "100", fontSize: "13px" }}
                  aria-describedby="emailHelp"
                  placeholder="Password"
                />
                <span
                  className="input-group-text"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </span>
              </div>
            </div>
            <div style={{ width: "80%" }} className="d-flex mt-3 flex-column">
              {/* <label for="exampleInputEmail1" class="form-label labels">Email</label> */}
              {/* value={value} onChange={(e) => setValue(e.target.value)}  */}
              <PhoneInput
                inputStyle={{ width: "100%", height: "55px" }}
                country={"ae"}
                value={logins.phone_no}
                // style={{ width: '100%', padding: '10px' }}
                // value={this.state.phone}
                onChange={(phone) => handleChange("phone_no", phone)}
              />
            </div>
            <div style={{ width: "80%" }} className="d-flex mt-3 flex-column">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  style={{ borderColor: "#333333" }}
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                />
                <label
                  class="form-check-label checkboxText"
                  for="flexRadioDefault1"
                >
                  I agree to the{" "}
                  <span className="checkblue">Terms of Service</span> &{" "}
                  <span className="checkblue"> Privacy Policy.</span>
                </label>
              </div>
              <div class="form-check mt-3">
                <input
                  class="form-check-input"
                  type="radio"
                  style={{ borderColor: "#333333" }}
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                />
                <label
                  class="form-check-label checkboxText"
                  for="flexRadioDefault1"
                >
                  I consent to receive promotional marketing materials via an
                  automated SMS text messaging system to the telephone number I
                  have provided for my account. Consent is not a condition to
                  register for an account or purchase any services. View
                  Getmycharter's
                  <span className="checkblue">SMS Texting Policy</span>
                </label>
              </div>
              {/* <FormControl>
  
  <RadioGroup
    aria-labelledby="demo-radio-buttons-group-label"
    defaultValue="female"
    name="radio-buttons-group"
  >
    <FormControlLabel value="female" control={<Radio />} label="I agree to the Terms of Service & Privacy Policy." />
    <FormControlLabel value="male" control={<Radio />} label="I consent to receive promotional marketing materials via an automated SMS text messaging system to the telephone number I have provided for my account. Consent is not a condition to register for an account or purchase any services. View Getmycharter's SMS Texting Policy" />
  
  </RadioGroup>
</FormControl> */}
            </div>
            <div
              style={{ width: "80%" }}
              className="my-5   d-flex justify-content-center"
            >{load? <Box sx={{ display: 'flex' }} className="justify-content-center">
            <CircularProgress />
          </Box>:
              <Button
                type="submit"
                style={{
                  height: "50px",
                  borderRadius: "10px",
                  transform: "none",
                }}
                fullWidth
                variant="contained"
              >
                Sign up
              </Button>}
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}
export default RegisterMainModal;
