import React from 'react'
import "./style.css";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import up from "../../assets/Up.png"
import qw12 from "../../assets/qw12.svg"
import loc from "../../assets/loc.svg"

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Button, Divider, Switch } from '@mui/material';
const BookingCard = ({item,type}) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
   console.log(item,"ff")
  return (
   
    <Paper elevation={3}  sx={{width:isSmallScreen?290:345,height:200,backgroundColor:"white",borderRadius:"22px"}} >
<div className=' d-flex justify-content-between p-1 px-4 align-items-center' style={{borderBottom:"0.7px solid grey"}}>
    <p className='mb-0 dateBooking'>{item.check_in_date.split('T')[0]}- 08:00 pm</p>

    <div className='d-flex align-items-center'>
    <p className='mb-0 remind'>Remind</p>
    <Switch  defaultChecked size="small"  /></div>
</div>
<div className='d-flex justify-content-center align-items-center mt-3  ' style={{gap:"17px"}}>
    <div >
        <img src={item?.booked_yatch.images[0]}  style={{width:"88px",height:"86px",borderRadius:"15px"}}/>
    </div>
    <div >
        <p className='mb-0 cardHead'>{item?.booked_yatch.title}</p>
        <div style={{gap:"7px"}} className='d-flex align-items-center'>
        <img src={loc} />
            <p className='mb-0 cardtext1'>{item?.booked_yatch.city}</p>
        </div>
        <div style={{gap:"7px"}} className='d-flex align-items-center'>
        <img src={qw12} />
            <p className='mb-0 cardtext1'>Booking ID:<span style={{color:"#246BBC"}}> #DR452SA6754</span></p>
        </div>
    </div>
</div>
<div className=' d-flex mt-3 justify-content-center'>
    {type=="cancell"&&
<Button variant='contained' style={{width:"85%",textTransform: 'none',borderRadius:"8x"   }} className='btnBooking'>View Dtail</Button>}
    {type=="complete"&&
    <div className=' d-flex gap-4 justify-content-center w-100 '>
<Button variant='outlined'  className="btnText white_space" style={{textTransform: 'none',borderRadius:"8x",width:isSmallScreen?"100px":"133px"   }} >Rebook</Button>
<Button variant='contained' style={{textTransform: 'none',borderRadius:"8x" ,width:isSmallScreen?"100px":"133px"  }} className='btnBooking white_space'>Add Review</Button>

</div>}
    {type=="upcoming"&&
    <div className=' d-flex gap-4 justify-content-center w-100 '>
<Button variant='outlined'  className="btnText white_space" style={{ width:isSmallScreen?"100px":"133px",textTransform: 'none',borderRadius:"8x"   }} >Help</Button>
<Button variant='contained' style={{textTransform: 'none',borderRadius:"8x",width:isSmallScreen?"100px":"133px"   }} className='btnBooking white_space'>View Detail</Button>

</div>}
   </div>
        </Paper>
  )
}

export default BookingCard