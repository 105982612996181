import React, { useEffect } from "react";
import bgImage from "../../assets/bgimage.png";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import "./style.css";
import { Label } from "@mui/icons-material";
import HomeCard from "./HomeCard";
import Review from "./Review";
import Slider from "react-slick";
import UpdateCard from "./UpdateCard";

import Autocomplete from "react-google-autocomplete";
import { Avatar, Rating } from "@mui/material";
import { Checkbox } from "primereact/checkbox";
import image from "../../assets/reprofile.png";
import heart from "../../assets/heart.svg";
import YatchCard from "../AllYatch/YatchCard";
import footer from "../../assets/footerimage.png";
import { useState } from "react";
import { Carousel } from "primereact/carousel";
import Faq from "./Faq";
import Marina from "./Marina";
import apiClient from "../../Api/apiClient";
import { Link, useNavigate } from "react-router-dom";
import Types from "./Types";
import Destination from "./Destination";
import HappyUser from "./HappyUser";
import Ads from "./Ads";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
const Home = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const myArray = [1, 2, 3, 4, 3, 3, 5, 6];
  const [yatch, setYatch] = useState();
  const [filter, setFilter] = useState({
    latitude: "",
    longitude: "",
    date: "",
    price: "",
  });
  const responsiveOptions = [
    {
      breakpoint: "1199px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "991px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const fetchYatch = async () => {
    const result = await apiClient.get("/yatchs/get/popular/listing");
    setYatch(result.data.yatchs);
    console.log(result);
  };
  useEffect(() => {
    fetchYatch();
  }, []);
  const updateLatLng = (newLat, newLng) => {
    setFilter((prevReg) => ({
      ...prevReg,
      latitude: newLat,
      longitude: newLng,
    }));
  };
  const handleChange = (key, value) => {
    setFilter({ ...filter, [key]: value });
  };
  const sendFilter = () => {
    const filteredFilter = Object.fromEntries(
      Object.entries(filter).filter(
        ([key, value]) => value !== null && value !== undefined && value !== ""
      )
    );

    // Check if there are any non-empty values
    // if (Object.keys(filteredFilter).length > 0) {
    //   console.log(filteredFilter);
      navigate("/yatch-list", { state: { filteredFilter } });
    // }
  };
  return (
    <div className="mt-3" style={{ backgroundColor: "#FAFBFC" }}>
      <div
        className="w-100 bg-container  "
        style={{
          backgroundImage: `url(${bgImage})`,
          width: "100%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center bottom",
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            mt={2}
            md={6}
            className=" px-4 d-flex justify-content-center align-items-center flex-column "
          >
            <p className={`bannerText` } style={{fontSize:isSmallScreen  && "16px", width: "60%" }}>
              Discover Dubai In new Way
            </p>
            <p className="banner ml-4" style={{fontSize:isSmallScreen  && "11px", width: "65%" }}>
              More than 40,000 private yacht rentals and bareboat charters near
              me and worldwide for your next boating trip
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            mt={2}
            md={6}
            className="d-flex justify-content-center my-5"
          >
            <div
              className="py-3  d-flex justify-content-center align-items-center flex-column"
              style={{
                width:isSmallScreen?"100%": "460px",
                backgroundColor: "white",
                borderRadius: "5px",
              }}
            >
              {/* <div className='d-flex justify-content-center  '> */}
              <p className="rightBanner w-75  my-4 ">
                The best solution for your yacht charter
              </p>
              {/* </div> */}
              <div className="px-4 " style={{ width: "88%" }}>
                <label className="label">Select a City</label>
                <Autocomplete
                  class="form-control w-100 "
                  style={{fontSize: "14px", fontWeight: "500", width: "85%" }}
                  apiKey="AIzaSyCnFD6bP1GG63UwYt375dHPE5KZ3QxGVY8"
                  onPlaceSelected={(place) => {
                    const lat = place.geometry.location.lat();
                    const lng = place.geometry.location.lng();
                    updateLatLng(lat, lng);

                    console.log("Latitude:", lat);
                    console.log("Longitude:", lng);
                    console.log(place);
                  }}
                />
                {/* <div className="custom-select-container">
      <select class="form-selec custom-select py-2 w-100" aria-label="Default select example" >
  <option selected>Select City</option>
  <option value="1">Dubai</option>
  <option value="2">Sharjah</option>
  <option value="3">Kuwait</option>
</select>
</div> */}
                <div class="my-4 datee">
                  <label class="label">
                    Starting Date
                  </label>
                  <input
                    onChange={(e) => handleChange("date", e.target.value)}
                    value={filter.date}
                    type="date"
                    style={{ fontSize: "14px", fontWeight: "500",color: "#606060",width: "100%" }}
                    placeholder="Add your dates"
                    class="form-control   duration "
                  />
                </div>
                <div class="my-4">
                  <label className="label">Duration per hours</label>
                  <select
                    onChange={(e) => handleChange("price", e.target.value)}
                    value={filter.price}
                    class="form-selec custom-select duration  w-100"
                    style={{ fontSize: "14px", fontWeight: "500",width: "85%" }}
                    placeholder="Duration per hours"
                    aria-label="Default select example"
                  >
                    <option selected>Price per hour</option>
                    <option value="500">500 AED</option>
                    <option value="800">800 AED</option>
                    <option value="1200">1200 AED</option>
                  </select>
                </div>
                <Button
                  variant="contained"
                  className="my-3"
                  fullWidth
                  sx={{
                    backgroundColor: "#246bbc",
                    fontSize: "18px",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    marginTop: "20px",
                    height: "60px",
                    textTransform: "capitalize",
                  }}
                  onClick={() => sendFilter()}
                >
                  Find My  Boat
                </Button>
                {/* <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Select City</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    // value={age}
    label="Select City"
    // onChange={handleChange}
  >
    <MenuItem value={10}>Dubai</MenuItem>
    <MenuItem value={20}>Sharja</MenuItem>
    <MenuItem value={30}>Kuwait</MenuItem>
  </Select>
</FormControl> */}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="px-2 mt-5">
        <Grid container>
          <Grid item xs={12} className="">
            <Types />
          </Grid>
          <Grid item xs={12} className="mb-2 mt-3 ">
            <Destination />
          </Grid>

          <p className="destinationHead my-5 text-center  w-100">
            Popular <span className="destinationHead1"> Listings</span>{" "}
          </p>
        </Grid>

        <div className="container px-5">
          <Grid spacing={1} container justify="center" alignItems="center">
            {yatch?.map((item) => (
              <Grid
                item
                xs={12}
                md={4}
                lg={4}
                className="d-flex justify-content-center"
              >
                <YatchCard item={item} />
              </Grid>
            ))}
          </Grid>
          <div className=" my-4 d-flex justify-content-center">
            {/* <Link to="yatch-list"> */}
            <Button
              variant="contained"
              onClick={() => navigate("yatch-list")}
              style={{
                color: "#f8f8f8",
                backgroundColor: "#246BBC",
                width: "174px",
                height: "60px",
                fontFamily: "poppins",
                fontWeight: 600,
              }}
            >
              Explore more
            </Button>
            {/* <h5 className='homeCard'>{'<<Explore more deals >>'}</h5> */}
            {/* </Link> */}
          </div>
        </div>
        {/* <HappyUser/> */}
        {/* <h5 className='reviewHead'>Available Marinas</h5>
      <div>
        <Marina/>
      </div> */}
        <p
          className="destinationHead mb-5 text-center"
          style={{ marginTop: "90px" }}
        >
          10,000+ Happy Explorers Loved{" "}
          <span className="destinationHead1"> GetMyCharter!</span>{" "}
        </p>
        {/* <h5 className='reviewHead'>Our Client Reviews</h5> */}
        <div className="mb-5 px-2">
        <HappyUser/>
          {/* <Carousel
            value={myArray}
            numVisible={1}
            numScroll={1}
            responsiveOptions={responsiveOptions}
            circular
            // autoplayInterval={3000}
            itemTemplate={(item, index) => (
              <HappyUser item={item} index={item.index} />
            )}
            draggable
            showDots={true}
            className="pt-5"
          /> */}
        </div>
        <Ads />
        <div className=" px-5 " style={{ marginTop: "85px" }}>
          <p className="destinationHead my-5 text-center">
            Get update with latest{" "}
            <span className="destinationHead1"> blog</span>{" "}
          </p>

          <UpdateCard />
        </div>

        {/* <h5 className='reviewHead'>Frequent ask qustions</h5>
      <div className='d-flex justify-content-center'>
      <h5 className='faqText w-75   ' style={{textAlign:"center"}}>
      Quisque vitae faucibus proin sed sollicitudin aliquet. Amet quis blandit mi auctor malesuada eget ipsum nisl. Eu, egestas in nulla sed vitae in etiam nunc. Urna neque.
      </h5>
      </div>
<div className=' d-flex justify-content-center'>

          <Faq/>
</div> */}
      </div>
      {/* <div>
      <img src={footer} style={{width:"100%",height:"70vh"}}/>
    </div> */}
    </div>
  );
};

export default Home;
