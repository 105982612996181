import { Avatar, Grid } from '@mui/material'
import React, { useState } from 'react'
import p from "../../assets/pr.svg"
import p1 from "../../assets/ch.svg"
import p2 from "../../assets/bokk.svg"
import p3 from "../../assets/listing.svg"
import p4 from "../../assets/fav.svg"
import p5 from "../../assets/log.svg"
import "./style.css"
import Fav from './Fav'
import Listing from './Listing'
import Booking from '../Booking/Booking'
import Profiles from './Profile'
const OwnerProfile = () => {
    const[show,setShow]=useState("Fav")
  return (
   <>
   <div className='container-fliud p-5'>
    <Grid container>
        <Grid item xs={2} className=' '>
            <div className='d-flex justify-content-center'>
        <Avatar
  alt="Fah Sharp"
  src="/static/images/avatar/1.jpg"
  sx={{ width: 56, height: 56 }}
/>
</div>
<p className='profTitle mt-3 text-center '>Yasir Mehmood</p>
<div className='d-flex align-items-center ' style={{gap:"12px"}} onClick={()=>setShow("Prof")}>
    <img src={p}/>
    <p className='mb-0 ite mt-2'>Profile</p>
</div>
<div className='d-flex align-items-center ' style={{gap:"12px"}}>
    <img src={p1}/>
    <p className='mb-0 ite mt-2'>Messges</p>
</div>
<div className='d-flex align-items-center ' style={{gap:"12px"}} onClick={()=>setShow("lis")}>
    <img src={p2}/>
    <p className='mb-0 ite mt-2'>Listings</p>
</div>
<div className='d-flex align-items-center ' style={{gap:"12px"}}>
    <img src={p3}/>
    <p className='mb-0 ite mt-2'>Bookings</p>
</div>
<div className='d-flex align-items-center ' style={{gap:"12px"}} onClick={()=>setShow("Fav")}>
    <img src={p4}/>
    <p className='mb-0 ite mt-2' >Favorites</p>
</div>
<div className='d-flex align-items-center ' style={{gap:"12px"}}>
    <img src={p5}/>
    <p className='mb-0 ite mt-2'>Logout</p>
</div>
        </Grid>
        <Grid item xs={10}>
        {show=="Prof"&& <Profiles/> }
        {show=="Fav"&& <Fav/> }
        {show=="lis"&& <Listing/>}
        {show=="booking"&&<Booking/>}
        </Grid>
    </Grid>

   </div>
   </>
  )
}

export default OwnerProfile