import { Button, CircularProgress, Grid } from '@mui/material'
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import "./style.css";
import apiClient from '../../Api/apiClient';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
const Price = ({data,next}) => {
    const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    let initialState={
        "yatchId" : data||"" ,
        "progress" :"60" ,
        // "time_between_bookings" : "00:30" ,
        price_per_hour : ""
    }
    const[load,setLoad]=useState(false)
    const[price,setPrice]=useState(initialState)
    const handleChange=(key,value)=>{
        setPrice({...price,[key]:value})
    }
    const handleSubmit=async(e)=>{
        e.preventDefault()
        setLoad(true)
        const result =await apiClient.post("/yatchs/create/third-screen",price)
        setLoad(false)
        if(result.error) return  toast.error("Error")
toast.success("Successfully Submit")
        console.log(result)
        next()
    }
  return (
   <>
   <Grid container className='px-3'>
    <form onSubmit={handleSubmit}>
    <Grid item xs={12} className=' ' >
        <p className='pricet'>
        Reference price
        </p>
        <p className='pricetext w-75'>
        Set a base rental price for your boat. This should be the lowest price you are willing to accept for rentals. You will then be able to add custom price periods
        </p>
    </Grid>
    <Grid item xs={12} style={{border:"1px solid #E6E6E6",borderRadius:"3px"}} >
        <p className='pricet mb-1 p-3' style={{fontSize:"17px",borderBottom:"1px solid #E6E6E6"}}>
        Price and options
        </p>
        <div className='d-flex p-3 justify-content-between' style={{backgroundColor:"#fbfbfb"}}>
    <div>
        <p className='mb-0 pricetag'>
        Price /hour
        </p>
        <p className='currency'>
        (0 AED)
        </p>
        </div>
        
    <input type='number'  value={price.price_per_hour} onChange={(e)=>{handleChange("price_per_hour",e.target.value)}} class="form-control  " style={{width:"60%",border:"none",height:"40px"}}  aria-describedby="emailHelp"/>
        
        </div>
        <div className='p-3 d-flex justify-content-end' style={{backgroundColor:"#fbfbfb"}}>
        {load?<CircularProgress />:
            
            <Button type='submit'  variant='contained'  style={{color:"white",fontFamily:"Poppins",fontWeight:500}}> Save</Button>}
        </div>
    </Grid>
    <div className={`mt-4 p-3 ${isSmallScreen?'W-100':'w-50'}`} style={{border:"1px solid #E6E6E6"}}>
        <p className='mb-0 currency1'>+ Create a new price period</p>
    </div>
    </form>
   </Grid>
   </>
  )
}

export default Price