import * as React from "react";
import { useRef, useState } from 'react';
import Slider from "react-slick";
import Card from "@mui/material/Card";
import "./syle.css";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import image from "../../assets/CardImage.png";
import pin from "../../assets/pin.svg";
import captain from "../../assets/captain.svg";
import captain1 from "../../assets/captain1.svg";
import arrow from "../../assets/Arrow 2.svg";
import star from "../../assets/star.svg";
import bath from "../../assets/bath.svg";
import pre from "../../assets/pvr.svg";
import ca from "../../assets/ca.svg";
import ed from "../../assets/ed.svg";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
const YatchCard = ({ item, test, fav, listing }) => {
  const theme = useTheme();
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));
  const [isFavorite, setFavorite] = React.useState(true);

  const handleFavoriteIconClick = (e) => {
    e.stopPropagation();

    // Toggle the favorite state
    setFavorite(!isFavorite);
  };
  const navigate = useNavigate();
  console.log(item.operators[0], "asasasas1");
  const [activeDot, setActiveDot] = useState(0);
  const sliderRef = useRef(null);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  
  const goToSlide = (index) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
    setActiveDot(index);
  };

  const totalSlides = item.images.length;

  return (
    <Card
      sx={{
        width: test ||(isXl?"370" : "328px"),
        height: listing ? "365px" : "352px",
        position: "relative",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)", // Add the boxShadow property here
        cursor: "pointer",
        borderRadius: "10px",
        marginBottom: "25px",
      }}
      onClick={() => navigate("/yatch-detail", { state: { item } })}
    >
      <Slider {...settings} ref={sliderRef}>
  {item.images.map((image, index) => (
    <div key={index} className="" style={{ position: 'relative' }}>
      <CardMedia
        sx={{ width: "100%", height: 218 }}
        image={image}
      />
       {/* <div className="d-flex justify-content-center mt-3">
        {[...Array(Math.ceil(totalSlides / settings.slidesToScroll)).keys()].map((index) => (
          <span
            key={index}
            className={`mb-1 dot ${index === activeDot ? 'active' : ''}`}
            onClick={() => goToSlide(index * settings.slidesToScroll)}
          />
        ))}
      </div> */}
    </div>
  ))}
</Slider>
      {fav && (
        <FavoriteIcon
          onClick={handleFavoriteIconClick}
          style={{
            position: "absolute",
            top: 6,
            right: 5,

            color: isFavorite ? "red" : "white",
          }}
        />
      )}
      <CardContent>
        <h5 className="cardHead mb-0">
          {item.title.length > 20
            ? `${item.title.substring(0, 20)}...`
            : item.title}
          {/* {item.title.length > 20 ? `${item.title.substring(0, 20)}...` : item.title} */}
        </h5>
        <h5 className="fw-bold cardText1 mb-0">{item.country || "Dubai"}</h5>

        <div className="d-flex w-100 mt-1  ">
          <div className="d-flex   align-items-center">
            <img src={captain1} className="mx-1" />
            <h5 className="cardText1 mb-0 " style={{ whiteSpace: "nowrap" }}>
              4{" "}
            </h5>
          </div>
          <div className="vertical-line"></div>
          <div className="d-flex  align-items-center">
            <img src={captain} className="mx-1" />
            <h5 className="cardText1 mb-0 " style={{ whiteSpace: "nowrap" }}>
              With {item.operators[0]}
            </h5>
          </div>
        </div>
        {listing ? (
          <>
            <div
              className="d-flex align-items-center mt-3"
              style={{ gap: "12px" }}
            >
              <img src={ed} />
              <p className="mb-0 " style={{ fontSize: 14 }}>
                Edit
              </p>
            </div>
            <div
              className="d-flex align-items-center mt-1"
              style={{ gap: "12px" }}
            >
              <img src={ca} />
              <p className="mb-0 " style={{ fontSize: 14 }}>
                Calender
              </p>
            </div>
            <div
              className="d-flex align-items-center mt-1"
              style={{ gap: "12px" }}
            >
              <img src={pre} />
              <p className="mb-0 " style={{ fontSize: 14 }}>
                Preview listing{" "}
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="d-flex mt-1" style={{ gap: "5px" }}>
              <img src={star} />
              <h5 className="cardText1 mb-0 " style={{ whiteSpace: "nowrap" }}>
                5.0 (34 bookings)
              </h5>
            </div>

            <h6 className="prices mt-2">From {item.price_per_hour}$ per day</h6>
          </>
        )}
        {/* <div  className='d-flex w-100  mt-2  justify-content-between'>
        <div className='d-flex   align-items-center' >
            <img src={arrow}/>
            <h5 className='cardText mb-0 ml-1'>{item.length_specs+" length"}</h5>
        </div>
        
        <div className='d-flex  align-items-center' >
            <img src={add}/>
            <h5 className='cardText mb-0 ml-1' style={{ whiteSpace: 'nowrap' }}>12 guest</h5>
        </div>
        
        <div className='d-flex   align-items-center'>
            <img src={bath}/>
            <h5 className='cardText mb-0 ml-1' style={{ whiteSpace: 'nowrap' }}>2 Bathroom</h5>
        </div>
        </div> */}
      </CardContent>
    </Card>
  );
};

export default YatchCard;
