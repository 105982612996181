import React, { useEffect, useState } from 'react'
import apiClient from '../../Api/apiClient'
import { Grid } from '@mui/material'
import YatchCard from '../AllYatch/YatchCard'

const Fav = () => {
    const [yatch,setYatch]=useState()
    const fetchYatch=async()=>{
        const result=await apiClient.get("/yatchs")
        setYatch(result.data.yatchs?.slice(0, 3))
        console.log(result)
      }
      useEffect(() => {
       fetchYatch()
      }, [])
  return (
    <Grid container>
        <Grid item xs={12}>
        <p className='favGead my-3'>Your favorites</p>
        </Grid>
     {yatch?.map((item)=>
    <Grid item xs={12} md={4} lg={4} className='d-flex justify-content-center'>
    <YatchCard item={item} fav={"fav"}/>
    </Grid>
    )}
    </Grid>
    
  )
}

export default Fav