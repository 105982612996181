import { Grid } from '@mui/material'
import React from 'react'
import "./style.css";
import {Rating ,useMediaQuery,ThemeProvider, createTheme } from '@mui/material';
const RatingCard = () => {
  const theme = createTheme(); // You can customize the theme if needed
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
return (
    <Grid container spacing={2} >
      <Grid item xs={12} >
      <h5 className='thing'>OverAll Rating</h5>
      </Grid>
      
      <Grid item xs={12} className={` d-flex   justify-content-between align-items-center w-100 ${isSmallScreen ? 'flex-wrap' : ''}`}>
      
       <div className='d-flex  w-75  px-3 justify-content-between'>
          <p className='mb-0 includeText'>Compliance of the boat</p>
          <Rating style={{ color: '#2D9CDB',fontSize: 16 }} name="no-value" value={3} />
          </div>
          <div  className='d-flex  w-75  px-3 justify-content-between' >
       
          <p className='mb-0 includeText'> Comfort on board</p>
          <Rating style={{ color: '#2D9CDB',fontSize: 16 }} name="no-value" value={3} />
          </div>
        
      </Grid>
      <Grid item xs={12} className={` d-flex justify-content-between align-items-center w-100 ${isSmallScreen ? 'flex-wrap' : ''}`}>
      <div className='d-flex  w-75 px-3 justify-content-between'>
          <p className='mb-0 includeText'>Standard of maintenance  </p>
          <Rating style={{ color: '#2D9CDB',fontSize: 16 }} name="no-value" value={3} />
      </div>
      <div className='d-flex  w-75 px-3 justify-content-between'>
       
          <p className='mb-0 includeText'>Cleanliness</p>
          <Rating style={{ color: '#2D9CDB',fontSize: 16 }} name="no-value" value={3} />
      </div>
        
      </Grid>
      <Grid item xs={12} className={` d-flex justify-content-between align-items-center w-100 ${isSmallScreen ? 'flex-wrap' : ''}`}>
      <div className='d-flex  w-75 px-3 justify-content-between'>
          <p className='mb-0 includeText'>Welcome and communication</p>
          <Rating style={{ color: '#2D9CDB',fontSize: 16 }} name="no-value" value={3} />
      </div>
      <div className='d-flex  w-75 px-3 justify-content-between'>
       
          <p className='mb-0 includeText'> Value for money</p>
          <Rating style={{ color: '#2D9CDB',fontSize: 16 }} name="no-value" value={3} />
      </div>
        
      </Grid>
    
     
      <Grid item xs={12} md={6}></Grid>
    </Grid>
  )
}

export default RatingCard