import { Box, Button, CircularProgress, Grid, Rating } from "@mui/material";
import React,{useEffect, useState} from "react";
import up from "../../assets/Up.png";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import card from "../../assets/card.svg";
import "./style.css";
import { useLocation, useNavigate } from "react-router-dom";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import apiClient from "../../Api/apiClient";
import { toast } from "react-toastify";

const Confrimation = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);
    const location = useLocation();
    const naviagte=useNavigate()
    const type = location.state?.Booking;
    console.log(type,"aaa")
    const stripe = useStripe();
    const elements = useElements();
    const [load,setLoad]=useState(false)
    const [error, setError] = useState(null);
  
    const handleSubmit = async (event) => {
        event.preventDefault();
    
        if (!stripe || !elements) {
          // Stripe.js has not yet loaded.
          return;
        }
    
        const cardElement = elements.getElement(CardElement);
    
        try {
          const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
          });
    
          if (error) {
            console.error(error);
            // Handle error appropriately
          } else {
            // Access the paymentMethod.id for the payment ID
            const paymentId = paymentMethod.id;
            console.log('Payment ID:', (type?.price+type.price*0.05).toFixed(2));
    const sendToken={
        "amountInDollars":(type?.price+type.price*0.05).toFixed(2),
        "paymentMethodId":paymentId,
        "dates": type?.dates,
        "check_in_time": type?.check_in_time,
        "check_out_time": type?.check_out_time,
        "yatchId": type?.yatchId
      }
      setLoad(true)
      const result =await apiClient.post("/bookings/instant/create",sendToken)
      setLoad(false)
      console.log(result)
      if (result.error) return toast.error(result.message|| "SomeThing Went Wrong Or Yatch Already Book for this Slot");

      toast.success(result.data.message);
      naviagte("/")
            // Send the payment ID to your server to handle the payment
            // You might want to handle the server-side part separately
          }
        } catch (error) {
          console.error('Error creating payment method:', error);
          // Handle error appropriately
        }
      };
      
  return (
   <>
    <div className="container">
      <div className="mt-4 d-flex align-items-center " style={{gap:"15px",cursor:"pointer"}} onClick={()=>naviagte("/")}>
        <ArrowBackIcon/>
      <p className="mb-0 backbutton">Confirmation & payment</p>
      </div>
    </div>
   <div className='container mt-4  d-flex justify-content-center'>
   <div className="" style={{width:"600px"}}>
            <div className="d-flex " style={{border:"1px solid grey",borderRadius:"10px"}}>
                <img src={type?.type?.images[0]} style={{width:"90px",height:"90px",borderTopLeftRadius:"10px",borderBottomLeftRadius:"10px"}}/>
                <div className="d-flex  w-100 flex-column  justify-content-center align-items-center">
                    <p className="chatcardtitle mb-0">{type?.type?.title}</p>
                    <p className="chatcardsub text-center mb-0">2018 View the listing</p>
                    <p className="chatcardsub text-center mb-0">{type?.type?.harbour}</p>
                </div>
            </div>
            <div className="d-flex w-100 mt-3 justify-content-between align-items-center">
                <div>
                <p className="listItemChat mb-0">{type?.type?.creator.username}</p>
                <p className="listItemChat mb-0">5.0 (34 Bookings)</p>
                </div>
                <img src={up} style={{width:"90px",height:"90px",borderRadius:"50px"}}/>
            </div>
            <div className="d-flex w-100 mt-3 justify-content-between align-items-center">
                <p className="listItemChat mb-0">Check- Out</p>
                <p className="listItemChat mb-0">{type?.dates[type?.dates.length-1]+"-"+type?.check_out_time}</p>
            </div>
            <div className="d-flex w-100 mt-3 justify-content-between align-items-center">
                <p className="listItemChat mb-0">Number of Guest</p>
                <p className="listItemChat mb-0">25 People</p>
            </div>
            <div className="d-flex w-100 mt-3 justify-content-between align-items-center">
                <p className="listItemChat mb-0" style={{fontWeight:"700"}}>Booking Details</p>
              
            </div>
            <div className="d-flex w-100 mt-3 justify-content-between align-items-center">
                <p className="listItemChat mb-0">Name</p>
                <p className="listItemChat mb-0">{type?.type?.creator.username}</p>
            </div>
            <div className="d-flex w-100 mt-3 justify-content-between align-items-center">
                <p className="listItemChat mb-0">Email</p>
                <p className="listItemChat mb-0">{type?.type?.creator.email}</p>
            </div>
            <div className="d-flex w-100 mt-3 justify-content-between align-items-center">
                <p className="listItemChat mb-0">Phone Number</p>
                <p className="listItemChat mb-0">{type?.type?.creator.phone_no}</p>
            </div>
            <div className="d-flex w-100 mt-3 justify-content-between align-items-center">
                <p className="listItemChat mb-0">Name</p>
                <p className="listItemChat mb-0">{type?.type?.creator.username}</p>
            </div>
            <div className="d-flex w-100 mt-3 justify-content-between align-items-center">
                <p className="listItemChat mb-0">Rental Price</p>
                <p className="listItemChat mb-0">{type?.price} Aed</p>
            </div>
            <div className="d-flex w-100 mt-3 justify-content-between align-items-center">
                <p className="listItemChat mb-0">Vat 5%</p>
                <p className="listItemChat mb-0">{type?.price*0.05 } Aed</p>
            </div>
            <div className="d-flex w-100 mt-3 justify-content-between align-items-center">
                <p className="listItemChat mb-0">Handing Fees</p>
                <p className="listItemChat mb-0">Free</p>
            </div>
            <div className="mt-3" style={{borderTop:"1px solid black"}}/>
            <div className="d-flex w-100 mt-1 justify-content-between align-items-center">
                <p className="listItemChat mb-0">Total</p>
                <p className="listItemChat mb-0" style={{color:"#246BBC"}}>{type?.price+type.price*0.05} Aed</p>
            </div>
           
            <p className="listItemChat mb-0 mt-3" style={{color:"#246BBC"}}>Add Promo code</p>
            <div className="mt-3">
                <p className="mb-1 listItemChat" style={{fontWeight:"700",color:"black"}}>Secure Payment</p>
                <p className="mb-0 listItemChat" style={{fontWeight:"500"}}>Once the booking request is made, the owner will have 48 hours to respond. You will only be charged if the request is accepted. </p>
           
           {/* <div className="mt-2">
           <label for="exampleInputEmail1" class="form-label labels">Card Number</label>
    <input placeholder="1234 1234 1234 1234 "   class="form-control  py-2" style={{fontSize:"14px ", fontWeight:"300",width:"75%"}} aria-describedby="emailHelp"/>
      
           </div>
           <div className="mt-2  d-flex">
            <div className="w-100">
           <label for="exampleInputEmail1" class="form-label labels">Expiration date</label>
    <input placeholder="MM /YY "   class="form-control  py-2" style={{fontSize:"14px ", fontWeight:"300",width:"80%"}} aria-describedby="emailHelp"/>
    </div>
            <div className="w-100">
           <label for="exampleInputEmail1" class="form-label labels">Expiration date</label>
    <input placeholder="CVC "   class="form-control  py-2" style={{fontSize:"14px ", fontWeight:"300",width:"80%"}} aria-describedby="emailHelp"/>
    </div>
           </div> */}
            <form onSubmit={handleSubmit}>
      <div className="mt-2">
        <label htmlFor="cardNumber" className="form-label labels">
          Card Number
        </label>
        <div className="p-3" style={{border:"1px solid grey",borderRadius:"10px"}}>
        <CardElement
          options={{
            style: {
              border:"1px solid red",
              base: { 
                padding:"23px",
                fontSize: '14px',
                fontWeight: '300',
                width: '75%',
              },
            },
          }}
        />
      </div>
      </div>
       <div className="d-flex w-100 mt-3 justify-content-between align-items-center">
       {load? <Box  sx={{ display: 'flex' }} className="w-100 justify-content-center">
            <CircularProgress />
          </Box>: <Button variant="contained" type="submit" style={{backgroundColor:"#246BBC",transform:"none",color:"white"}} fullWidth>Validate and pay</Button>
 } </div>
      </form>
           {/* <form onSubmit={handleSubmit}>
      <div className="mt-2">
        <label htmlFor="cardNumber" className="form-label labels">
          Card Number
        </label>
        <input
          type="text"
          id="cardNumber"
          placeholder="1234 1234 1234 1234"
          className="form-control py-2"
          style={{ fontSize: '14px', fontWeight: '300', width: '100%' }}
        />
      </div>

      <div className="mt-2 d-flex">
        <div className="w-50">
          <label htmlFor="expirationDate" className="form-label labels">
            Expiration date
          </label>
          <input
            type="text"
            id="expirationDate"
            placeholder="MM /YY"
            className="form-control py-2"
            style={{ fontSize: '14px', fontWeight: '300', width: '100%' }}
          />
        </div>
        <div className="w-50">
          <label htmlFor="cvc" className="form-label labels">
            CVC
          </label>
          <input
            type="text"
            id="cvc"
            placeholder="CVC"
            className="form-control py-2"
            style={{ fontSize: '14px', fontWeight: '300', width: '100%' }}
          />
        </div>
      </div>

      <div className="d-flex w-100 mt-3 justify-content-between align-items-center">
        <button
          type="submit"
          className="btn btn-primary"
          style={{ backgroundColor: '#246BBC', color: 'white', width: '100%' }}
        >
          Validate and pay
        </button>
      </div>
    </form> */}
            </div>
            {/* <div className="d-flex w-100 mt-3 justify-content-between align-items-center">
              <Button variant="contained" style={{backgroundColor:"#246BBC",transform:"none",color:"white"}} fullWidth>Validate and pay</Button>
            </div> */}
            <div className="d-flex justify-content-center mt-3">
            <img src={card}/>
            </div>
          </div>
   </div>
   </>
  )
}

export default Confrimation