import { Grid, Paper } from '@mui/material';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function LogoutModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         Yatch Images
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Grid container spacing={1}>
          {props?.images.map((item) => (
            <Grid item xs={6}>
              <Paper className="mb-1">
                {/* Your second image */}
                <img
                  src={item}
                  alt="Image 2"
                  style={{
                    borderRadius: "5px",
                    objectFit: "cover",
                    width: "100%",
                    height: "175px",
                    position: "relative",
                  }}
                />
              
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Modal.Body>
      
    </Modal>
  );
}


export default LogoutModal