import { create } from "apisauce";
import axios from "axios";
const baseURL = "https://getmycharterbackken.onrender.com/api";


  const apiClient= axios.create({
    baseURL:baseURL
  });
  const authToken = localStorage.getItem('Token')
  console.log(authToken,"ddddddddddddddddddd")
    if (authToken)
  apiClient.defaults.headers.common = {'Authorization': `Bearer ${authToken}`}

  apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
      // console.error('API Request Failed:', error);
  
      // Returning a consistent response format
      return Promise.resolve({
        data: null, // or any default value you want
        error: error.message || 'An error occurred during the API request.',
      });
    })

export default apiClient
export {baseURL}




