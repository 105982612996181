import { createSlice } from '@reduxjs/toolkit';
import apiClient from '../Api/apiClient';

const initialState = {
  token: localStorage.getItem('Token') || null,
  userType: localStorage.getItem('userType') || null,
  isLoggedIn: !!localStorage.getItem('Token'),
  userId:localStorage.getItem('userId') || null,
 
};
//console.log(initialState);

export const counterSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
  
    login: (state, {payload}) => {
      
      console.log(payload,"pay")
      localStorage.setItem('Token',payload.token)
      localStorage.setItem('userType',payload.userType)
      localStorage.setItem('userId',payload.userId)
      state.token = payload.token;
      state.userType = payload.userType;
      state.userId=payload.userId
      state.isLoggedIn=true

    },
    logout: (state) => {
        state.token = null;
        state.userType =null;
        state.isLoggedIn=false
        localStorage.removeItem('Token')
      localStorage.removeItem('userType')
     
      },
     
    
 


  }
});

// Action creators are generated for each case reducer function
export const {
login,
logout,
} = counterSlice.actions;

export default counterSlice.reducer;
