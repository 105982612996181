import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const { isLoggedIn } = useSelector(state => state.auth);
    const navigate = useNavigate();
    console.log(children, "s");
  
    useEffect(() => {
      if (!isLoggedIn) {
        console.log("ssssssssssssssssssssssss1");
        navigate("/");
      }
       
    }, [isLoggedIn]);
  
    
    return isLoggedIn ? children : null;
    
    
  };

export default ProtectedRoute