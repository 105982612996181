import React, { useEffect, useState } from 'react'
import m1 from "../../assets/m1.svg";
import m2 from "../../assets/m2.svg";
import m3 from "../../assets/m3.svg";
import m4 from "../../assets/m4.svg";
import m5 from "../../assets/m5.svg";
import m6 from "../../assets/m6.svg";
import m7 from "../../assets/m7.svg";
import { Avatar, Box, Button, CircularProgress, Grid } from '@mui/material';
import "./style.css";
import apiClient from '../../Api/apiClient';
import { toast } from 'react-toastify';
import Security from '../supprt/Security';
import HelpSupport from '../supprt/HelpSupport';
import Report from '../supprt/Report';
import TermPolicy from '../supprt/TermPolicy';
const Profile = () => {
  const[proData,setProData]=useState()
  const[load,setLoad]=useState(false)
  let initialState={
    "username"  :proData?.username||"" ,
    "email" :proData?.email|| "" ,
    "gender" : proData?.gender||"" ,
    "dob" : proData?.dob||"" ,
    "city"  :proData?.city||""
  }
  const[profile,setProfile]=useState(initialState)
  const fetchProfile=async()=>{
    const result =await apiClient.get("/users/renter/info")
    if (result.error) return
    setProData(result.data.user)
    setProfile({...profile,username:result.data.user.username,email:result.data.user.email})
    console.log(result)
  }
  useEffect(()=>{
    fetchProfile()
  },[])
  const handleChange=(key,value)=>{
    setProfile({...profile,[key]:value})
  }
  const handleSubmit=async(e)=>{
e.preventDefault()
setLoad(true)
const result=await apiClient.patch("users/renter/info/update",profile)
setLoad(false)
if (result.error) return toast.error("Error");
toast.success("Successfully update");
  }
  const[step,setStep]=useState(1)
  return (
   <>
   <div className='container'>
    <Grid container>
   
    <Grid item xs={12} md={3}>
   
      
        <div className="mt-4">
        <h6 className="mb-0 mnuHead" style={{cursor:"pointer"}} >
             Account
            </h6>
        </div>
       
          <div className="d-flex mt-4 "  onClick={()=>setStep(1)} style={{ cursor:"pointer",gap:"10px"}}>
            <img src={m1}/>
            <p className='mb-0 mnutext'>
         Edit profile
         </p>
          </div>
       
       
        <div className="d-flex mt-4 "  onClick={()=>setStep(2)} style={{ cursor:"pointer",gap:"10px"}}>
            <img src={m2}/>
            <p className='mb-0 mnutext'>
         Security
         </p>
         </div>
      
      
        <div className="d-flex mt-4 "  style={{ cursor:"pointer",gap:"10px"}}>
            <img src={m3}/>
            <p className='mb-0 mnutext'>
         Notification
         </p>
         </div>
        
       
        <div className="mt-3" >
        <h6 className="mb-0 mnuHead " style={{cursor:"pointer"}} >
        Support & About
            </h6>
        </div>
      
        <div className="d-flex mt-4 "  onClick={()=>setStep(3)} style={{ cursor:"pointer",gap:"10px"}}>
            <img src={m4}/>
            <p className='mb-0 mnutext'>
            Help & Support
            </p>
         </div>
    
      
        <div className="d-flex mt-4 "  onClick={()=>setStep(5)}  style={{ cursor:"pointer",gap:"10px"}}>
            <img src={m5}/>
            <p className='mb-0 mnutext'>
            Terms & Policies
            </p>
         </div>
      
        <div className="mt-3" >
        <h6 className="mb-0 mnuHead " style={{cursor:"pointer"}} >
        Actions
            </h6>
        </div>
      
        <div className="d-flex mt-4 " onClick={()=>setStep(4)}    style={{ cursor:"pointer",gap:"10px"}}>
            <img src={m6}/>
            <p className='mb-0 mnutext'>
            Report a problem
            </p>
         </div>
       
        <div
        //  onClick={()=>{handleClose()
        // dispatch(logout())
        // }} 
       >
        <div className="d-flex mt-4 " style={{ cursor:"pointer",gap:"10px"}}>
            <img src={m7}/>
            <p className='mb-0 mnutext'>
            Log out
            </p>
         </div>
        </div>
     
    </Grid>
    
    <Grid item xs={12} md={9}>
      {(step==1) &&
    <form onSubmit={handleSubmit}>
        <h6 className='head mt-3 text-center'>Edit Profile</h6>
        <div className='d-flex flex-column justify-content-center align-items-center'>
        <Avatar
  alt="Remy Sharp"
  src="/static/images/avatar/1.jpg"
  sx={{ width: 80, height: 80 }}
/>
<div>
<div class="my-4 datee">
                  <label for="exampleInputEmail1" class="titleField">
                    Name
                  </label>
                  <input
                   
                 value={profile.username}
                 onChange={(e)=>handleChange("username",e.target.value)}
                    placeholder="enter names"
                    class="form-control "
                  />
                </div>
<div class="my-4 datee">
                  <label for="exampleInputEmail1" class="titleField">
                    Email
                  </label>
                  <input
                   
                   value={profile.email}
                 onChange={(e)=>handleChange("email",e.target.value)}
                    placeholder="enter email"
                    class="form-control "
                  />
                </div>
                <div class="my-4">
                  <label className="titleField">Gender</label>
                  <select
                  
                    class="form-selec custom-select   w-100"
                    value={profile.gender}
                    onChange={(e)=>handleChange("gender",e.target.value)}
                   
                    aria-label="Default select example"
                  >
                    <option selected>Gender</option>
                    <option value="m">Male</option>
                    <option value="f">Female</option>
                  
                  </select>
                </div>
                <div class="my-4 datee">
                  <label for="exampleInputEmail1" class="titleField">
                    Date of Birth
                  </label>
                  <input
                      value={profile.dob}
                      onChange={(e)=>handleChange("dob",e.target.value)}
                 type='date'
                    placeholder="Add your dates"
                    class="form-control "
                  />
                </div>
                {load? <Box sx={{ display: 'flex' }} className="justify-content-center">
            <CircularProgress />
          </Box>:
                <Button
                  variant="contained"
                  className="my-3"
                  fullWidth 
                  type='submit'
                  sx={{
                    backgroundColor: "#246bbc",
                    fontSize: "16px",
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    marginTop: "20px",
                    height: "50px",
                    textTransform: "capitalize",
                  }}
                 
                >
                  Save Changes
                </Button>}
</div>
        </div>
    </form>}
    {(step==2)&&<Security/>}
    {(step==3)&&<HelpSupport />}
    {(step==4)&&<Report />}
    {(step==5)&&<TermPolicy />}
    </Grid>
    </Grid>
   </div>
   </>
  )
}

export default Profile