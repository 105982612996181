import React from "react";
import "./style.css";
import { Grid } from "@mui/material";
import { useMemo } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
const Location = ({ item }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCnFD6bP1GG63UwYt375dHPE5KZ3QxGVY8",
  });
  const center = useMemo(
    () => ({ lat: item.latitude, lng: item.longitude }),
    []
  );
  return (
    <Grid container>
      <Grid item xs={12}>
        <h6 className="include mb-5">Location of boat</h6>
        {!isLoaded ? (
          <h1>Loading...</h1>
        ) : (
          <GoogleMap
            mapContainerClassName="map-container"
            center={center}
            zoom={10}
          >
            <Marker position={center} />
          </GoogleMap>
        )}
        {/* <iframe
  width="100%"
  height="370"
  frameborder="0"
  marginheight="0"
  marginwidth="0"
  src="https://maps.google.com/maps?q=25.07663615,55.14021869600694&width=100%&height=600&hl=en&t=&z=14&ie=UTF8&iwloc=B&output=embed"
></iframe> */}
        {/* <iframe width="100%" height="370" frameborder="0" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=dubai%20marina%20mall+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> */}
      </Grid>
      {/* <Grid item xs={12} md={6} className=' d-flex justify-content-center'>
            <div className=' w-50'>
            <h6 className='title text-center'>Booking options</h6>
            <h6 className='captain mt-5 '>Captained</h6>
            <div className='  w-100 d-flex justify-content-between'>
                <h6 className='time'>2 hours</h6>
                <h6 className='time'>$ 75</h6>
            </div>
            <div className='  w-100 d-flex justify-content-between'>
                <h6 className='time'>3 hours</h6>
                <h6 className='time'>$ 85</h6>
            </div>
            <div className='  w-100 d-flex justify-content-between'>
                <h6 className='time'>4 hours</h6>
                <h6 className='time'>$ 1100</h6>
            </div>
            </div>
        </Grid> */}
    </Grid>
  );
};

export default Location;
