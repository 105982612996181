import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';


import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Provider, useSelector } from "react-redux";
import {store} from './redux/store'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer } from "react-toastify";
const root = ReactDOM.createRoot(document.getElementById('root'));

const stripePromise = loadStripe('pk_test_51OCIHMFcvkV7Fl5HQQuJN0jz8kqxuLN61u5cEZrfzQoFYJ4cDzKrnoovY84VK0VKmDnhPw4NQ0paD7wunVdVHdL500Wbb5i7pJ');
root.render(
<GoogleOAuthProvider clientId="1015371492244-fb0umldr8aj8lh7o230atshp8t4gjg95.apps.googleusercontent.com">
   <Provider store={store}>

<PrimeReactProvider>
<Elements stripe={stripePromise}>
   <App/>
   </Elements>
   <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
   </PrimeReactProvider>
   </Provider>
</GoogleOAuthProvider>
);