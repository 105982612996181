import React, { useRef, useState } from 'react';
import Slider from "react-slick";
import image from "../../assets/Image (1).png";
import image1 from "../../assets/Image (2).png";
import image2 from "../../assets/Image (3).png";
import image3 from "../../assets/Image (3).png";
import image4 from "../../assets/Image (4).png";

const UpdateCard = () => {
  const myArray = [image, image1, image2, image4, image3];
  const [activeDot, setActiveDot] = useState(0);
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
  };

  const goToSlide = (index) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
    setActiveDot(index);
  };

  const totalSlides = myArray.length;

  return (
    <div className="" style={{ marginTop: "65px" }}>
      <Slider {...settings} ref={sliderRef}>
        {myArray.map((item, index) => (
          <div key={index} className="px-3 py-4">
            <div className="image-container" style={{ maxWidth: "100%" }}>
              <img src={item} style={{ width: "100%", height: "auto" }} alt="..." />
            </div>
            <div className="card-body mt-3 ">
              <h5 className="card-title py-3 " style={{ fontSize: "18px", fontFamily: "poppins", fontWeight: "500", color: "#222222" }}>The Amazing Difference a Year of Traveling</h5>
              <h5 className="upCardText " style={{ color: "#999999", fontFamily: "Inter", fontWeight: "400", fontSize: "16px" ,lineHeight:'26px'}}>July 27, 2021</h5>
            </div>
          </div>
        ))}
      </Slider>

      <div className="d-flex justify-content-center mt-3">
        {[...Array(Math.ceil(totalSlides / settings.slidesToScroll)).keys()].map((index) => (
          <span
            key={index}
            className={`mb-1 dot ${index === activeDot ? 'active' : ''}`}
            onClick={() => goToSlide(index * settings.slidesToScroll)}
          />
        ))}
      </div>
    </div>
  );
}

export default UpdateCard;
