import React from 'react'
import image1 from "../../assets/Button - Catamaran.png"
import image2 from "../../assets/Button - Jet ski.png"
import image3 from "../../assets/Button - Motorboat.png"
import image4 from "../../assets/Button - RIB.png"
import image5 from "../../assets/Button - Sailboat.png"
import image6 from "../../assets/Button - Yacht.png"
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
const Types = ({sendFilter,handleChange}) => {
 
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate=useNavigate()
    const type=[
      {
        img:image6,
        name:"Yacht"
        },
        {
      img:image1,
      name:"Catamaran"
      },
        {
      img:image2,
      name:"JetSki"
      },
        {
      img:image3,
      name:"Moterboat"
      },
        {
      img:image4,
      name:"RIB"
      },
        {
      img:image5,
      name:"Sailboat"
      },
      
    ]
  return (
    <>
    <p className='text-center my-4 typeHead  '>Types</p>
    <div className=' d-flex flex-wrap justify-content-center mt-5   ' style={{gap:"100px"}}>
    {
        type.map((item)=>
            <div className='' style={{cursor:"pointer",width:isSmallScreen&&"100%"}} onClick={()=>navigate("/yatch-list", { state: {selectType:item.name}})}>
            <img src={item.img} style={{width:isSmallScreen?"100%":"120px",height:isSmallScreen?"200px":"120px",objectFit:"cover"}}/>
            <p className='mb-0 type mt-3 text-center' style={{color:"#343A40",fontSize:"20px",fontWeight:"400",fontFamily: "Poppins"}}>{item.name}</p>
            </div>
        )
    }
    </div>
    </>
  )
}

export default Types
