import { Button, CircularProgress, Grid } from '@mui/material'
import React, { useState } from 'react'
import "./style.css";
import { toast } from 'react-toastify';
import apiClient from '../../Api/apiClient';
const Cancellation = ({data,next}) => {
    let initialState={
        "yatchId" : data||"" ,
        "progress" :"80" ,
        // "time_between_bookings" : "00:30" ,
        "cancellation_policy" : ""
    }
    const[load,setLoad]=useState(false)
    const[camcell,setCamcell]=useState(initialState)
    const handleChange=(key,value)=>{
        setCamcell({...camcell,[key]:value})
    }
    const handleSubmit=async(e)=>{
        e.preventDefault()
        setLoad(true)
        const result =await apiClient.post("/yatchs/create/fourth-screen",camcell)
        setLoad(false)
        if(result.error) return  toast.error("Error")
toast.success("Successfully Submit")
        console.log(result)
        next()
    }
  return (
    <>
    <Grid container className='px-3'>
       <form onSubmit={handleSubmit}>
        <Grid item xs={12}>
            <p className='main'>
            Cancellation Policy
            </p>
        </Grid>
        <Grid item xs={12}  className='d-flex align-items-center justify-content-between'  style={{borderBottom:"1px solid #E6E6E6"}}>
            <div>
            <p className=' mb-0 mainsub'>
            Flexible Cancellation Policy:
            </p>
            
            <ul >
        <li className=' w-75'>
        <p className='maintext mb-0'>
            Bookings can be cancelled at any time before 24 hours of the scheduled charter with a 100% refund.
            </p>
            </li>
    </ul>
    </div>
    <div>
    <input required value={camcell.cancellation_policy} onChange={()=>handleChange("cancellation_policy","flexible")} class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
    </div>
        </Grid>
        <Grid item xs={12} className='d-flex align-items-center justify-content-between'   style={{borderBottom:"1px solid #E6E6E6"}}>
            <div>
            <p className=' mb-0 mainsub'>
            Moderate Cancellation Policy:
            </p>
            
            <ul >
        <li className=' w-75'>
        <p className='maintext mb-0'>
        Cancellation before 48 hours of the scheduled charter will result in a 100% refund </p>
            </li>
        <li className=' w-75'>
        <p className='maintext mb-0'>
        If cancellation is made between 48 and 24 hours before the charter, a 20% fee will be deducted, and the remaining 80% will be refunded.</p>
            </li>
    </ul>
    </div>
    <div>
    <input required value={camcell.cancellation_policy} onChange={()=>handleChange("cancellation_policy","moderate")} class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
    </div>
        </Grid>
        <Grid item xs={12} className='d-flex align-items-center justify-content-between'    style={{borderBottom:"1px solid #E6E6E6"}}>
           <div>
            <p className=' mb-0 mainsub'>
            Strict Cancellation Policy:
            </p>
            
            <ul >
        <li className=' w-75'>
        <p className='maintext mb-0'>
        Cancellation before 24 hours of the scheduled charter will result in a 50% refund. </p>
            </li>
    </ul>
    </div>
    <div>
    <input required value={camcell.cancellation_policy} onChange={()=>handleChange("cancellation_policy","strict")} class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
    </div>
        </Grid>
        <Grid item xs={12} className='p-3 d-flex justify-content-end'>
        {load?<CircularProgress />:
        <Button type='submit'  variant='contained'  style={{color:"white",fontFamily:"Poppins",fontWeight:500}}> Save</Button>}
        </Grid>
        </form> 
    </Grid>
    </>
  )
}

export default Cancellation