import { Button, CircularProgress, Grid, Paper, TextField } from "@mui/material";
import React, { useState } from "react";
import "./style.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import motor from "../../assets/Motor.svg"
import sail from "../../assets/sail.svg"
import rib from "../../assets/rib.svg"
import cata from "../../assets/cata.svg"
import home from "../../assets/home.svg"
import { toast } from 'react-toastify';
import boat from "../../assets/boat.svg"
import yatch from "../../assets/yatch.svg"
import jet from "../../assets/jet.svg"
import gulet from "../../assets/gulet.svg"
import camra from "../../assets/camra.svg"
import camraa from "../../assets/cam.svg"
import { useNavigate } from "react-router-dom";
import apiClient from "../../Api/apiClient";

import Autocomplete from "react-google-autocomplete"
const Register = () => {
  let initialState={
  title: "",
  city:"",
  description: "",
  type_specs: "",
  made_by_specs: "",
  model_specs: "",
  harbour: "",
  psngr_capacity_specs: "",
  no_of_cabins: "",
  no_of_berths: "",
  no_of_washrooms: "",
  length: "",
  speed: "",
  latitude: "",
  longitude: "",
  progress: "20"
  }
  const[reg,setReg]=useState(initialState)
  const[load,setLoad]=useState(false)
  const[select,setSelect]=useState()
  const handleChange=(key,value)=>{
    setReg({...reg,[key]:value})
  }
  const navigate = useNavigate();
    const boats=[
        {
            icon:motor,
            name:"Moterboat"
        },
        {
            icon:sail,
            name:"Sailboat"
        },
        {
            icon:rib,
            name:"RIB"
        },
        {
            icon:cata,
            name:"Catamaran"
        },
        // {
        //     icon:home,
        //     name:"Houseboat"
        // },
        // {
        //     icon:boat,
        //     name:"Boat without license"
        // },
        {
            icon:yatch,
            name:"Yacht"
        },
        {
            icon:jet,
            name:"Jet ski"
        },
        // {
        //     icon:gulet,
        //     name:"Gulet"
        // },
    ]
   
const handleSubmit=async(e)=>{
e.preventDefault()
console.log(reg,"ssssss")
setLoad(true)
const result=await apiClient.post("/yatchs/create/first-screen",reg)
setLoad(false)
console.log(result,"aaaaaaaaaaaaaaa")
if(result.error) return  toast.error("Error")
toast.success("Successfully Submit")
// console.log(result,"k")
navigate('/register2',{state:result?.data?.yatch?._id})
}
const updateLatLng = (newLat, newLng) => {
  setReg((prevReg) => ({
    ...prevReg,
    latitude: newLat,
    longitude: newLng,
  }));
};
const harbourOptions = [
  {label: 'Marina Del Rey', value: 'Marina Del Rey'},
  {label: 'Long Beach', value: 'Long Beach'},
  {label: 'Newport Harbor', value: 'Newport Harbor'},
  {label: 'Miami Beach Marina', value: 'Miami Beach Marina'},
  {label: 'Sydney Harbour', value: 'Sydney Harbour'},
  {label: 'Portofino Harbour', value: 'Portofino Harbour'},
  {label: 'Monaco Port Hercules', value: 'Monaco Port Hercules'},
  {label: 'Dubai Marina', value: 'Dubai Marina'},
  {label: 'Hong Kong Victoria Harbour', value: 'Hong Kong Victoria Harbour'},
 
];
  return (
    <>
    
      <div className="container mt-4">
        <Grid item container>
          <Grid item xs={12}>
            <h6 className="head">Register your boat</h6>
          </Grid>
          <Grid item xs={12} className="my-3">
            <Paper elevation={2} className="py-1">
              <h6 className="head mx-5" style={{color:"#383c40"}}>Ahoy there Captain!</h6>
            </Paper>
          </Grid>
          <Grid item xs={12} mt={1}>
            <h6 className="sub">
              Your yacht will soon be visible to the largest community of
              sailors interested in peer-to-peer yacht charters. Welcome to get
              my charter!
            </h6>
          </Grid>
          
        </Grid>
        <form onSubmit={handleSubmit}>
        <Grid container spacing={3} mb={2}>

          <Grid item xs={12} className="" >
          {/* <Grid item xs={12} > */}
            <h6 className="personal py-2 mb-4" style={{ borderBottom: "1px solid #DEDEDE", }}>Title</h6>
          {/* </Grid> */}
          {/* <label for="exampleInputEmail1" class="form-label labels">First Name</label> */}
    <input value={reg.title} onChange={(e)=>handleChange("title",e.target.value)} placeholder="E.g.: Fountaine Pajot Bahia 46"  class="form-control title mt-2 py-3" aria-describedby="emailHelp"/>
          </Grid>
          <Grid item xs={12} className="" >
          {/* <Grid item xs={12} > */}
            <h6 className="personal py-2 mb-4" style={{ borderBottom: "1px solid #DEDEDE", }}>Description</h6>
          {/* </Grid> */}
          {/* <label for="exampleInputEmail1" class="form-label labels">First Name</label> */}
    <textarea value={reg.description} onChange={(e)=>handleChange("description",e.target.value)} rows={15} placeholder="The length and quality of your description impacts the positioning of your listing in the search results."  class="form-control titledes  py-3" aria-describedby="emailHelp"/>
          </Grid>
          {/* <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">Surname</label>
    <input  class="form-control  py-2" style={{width:"85%"}} aria-describedby="emailHelp"/>
          </Grid>
          <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">Surname</label>
    <input  class="form-control  py-2" style={{width:"85%"}} aria-describedby="emailHelp"/>
          </Grid>
          <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">Email</label>
    <input  class="form-control  py-2" style={{width:"85%"}} aria-describedby="emailHelp"/>
          </Grid>
          <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">Password</label>
    <input  class="form-control  py-2" style={{width:"85%"}} aria-describedby="emailHelp"/>
          </Grid>
          <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">Re-enter the password</label>
    <input  class="form-control  py-2" style={{width:"85%"}} aria-describedby="emailHelp"/>
          </Grid> */}
          {/* <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="SurName"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Telephone"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Email"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Password"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Re-Enter Password"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <label>How did you find out about Getmycharter?</label>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={age}
                label="select"
                // onChange={handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item xs={12} mt={2}>
                <h6 className="yatchType">Your yacht</h6>
                <hr
        style={{ width: "100%", margin: "auto", borderTop: "1px solid #DEDEDE" }}
      />   
          </Grid>

            <Grid item xs={12}>
                
                    <h6 className="yatchType1">Type</h6>
                    <div className=" container d-flex flex-wrap justify-content-around">
                        {boats.map((item,index)=>
                <div className="flex-column m-4" onClick={()=>{handleChange("type_specs",item.name)
              setSelect(index)
              }} 
                 style={{width:"120px",height:"120px",borderRadius:"8px",border:`1px solid ${select==index?"blue":"#DEDEDE"}`,display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img src={item.icon}/>
                    <h6 className="type">{item.name}</h6>
                </div>
                )}
                </div>
            </Grid>

            <Grid item xs={12} md={6}>
            <label for="exampleInputEmail1" class="form-label labels">City</label>
      <Autocomplete
      class="form-control  py-2" style={{width:"85%"}} 
  apiKey="AIzaSyCnFD6bP1GG63UwYt375dHPE5KZ3QxGVY8"
  onPlaceSelected={(place) => {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    updateLatLng(lat, lng);

    console.log("Latitude:", lat);
    console.log("Longitude:", lng);console.log(place)}}
    /> 
  
          {/* <label for="exampleInputEmail1" class="form-label labels">City</label>
    <input value={reg.city} onChange={(e)=>handleChange("city",e.target.value)}  class="form-control  py-2" style={{width:"85%"}} aria-describedby="emailHelp"/> */}
          </Grid>

          <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">Harbour</label>
          <div>
          <select value={reg.harbour} onChange={(e)=>handleChange("harbour",e.target.value)} class="form-select py-2 " aria-label="Default select example" style={{borderRadius:"5px",border:"1px solid #BFC7D2",width:"85%"}}>
  <option selected>select</option>
  {harbourOptions?.map((item)=>
  <option value={item.value}>{item.label}</option>
  )}
  {/* <option value="2">Two</option>
  <option value="3">Three</option> */}
</select>
</div>
          </Grid>
          {/* <Grid item xs={12} >
          <label for="exampleInputEmail1" class="form-label labels">Are you a professional?</label>
          <div>
          <select class="form-select py-2 " aria-label="Default select example" style={{width:"42%",borderRadius:"5px",border:"1px solid #BFC7D2"}}>
  <option selected>select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
</div>
          </Grid> */}
          <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">Manufacturer</label>
    <input value={reg.made_by_specs} onChange={(e)=>handleChange("made_by_specs",e.target.value)}  class="form-control  py-2" style={{width:"85%"}} aria-describedby="emailHelp"/>
          </Grid>
          <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">Model</label>
    <input value={reg.model_specs} onChange={(e)=>handleChange("model_specs",e.target.value)}  class="form-control  py-2" style={{width:"85%"}} aria-describedby="emailHelp"/>
          </Grid>
          <Grid item xs={6} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">Is your boat rented with a skipper?</label>
    <input  class="form-control  py-2" style={{width:"85%"}} aria-describedby="emailHelp"/>
          </Grid>
          <Grid item xs={12} mt={2}>
                <h6 className="yatchType">Capacity</h6>
                <hr
        style={{ width: "100%", margin: "auto", borderTop: "1px solid #DEDEDE" }}
      />   
          </Grid>

          <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">Onboard capacity</label>
    <input  class="form-control  py-2" style={{width:"85%"}} aria-describedby="emailHelp"/>
          </Grid>
          <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">Number of cabins</label>
    <input type="number" value={reg.no_of_cabins} onChange={(e)=>handleChange("no_of_cabins",e.target.value)} class="form-control  py-2" style={{width:"85%"}} aria-describedby="emailHelp"/>
          </Grid>
          <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">Number of berths</label>
    <input type="number" value={reg.no_of_berths} onChange={(e)=>handleChange("no_of_berths",e.target.value)} class="form-control  py-2" style={{width:"85%"}} aria-describedby="emailHelp"/>
          </Grid>
          <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">Number of washrooms</label>
    <input  type="number" value={reg.no_of_washrooms} onChange={(e)=>handleChange("no_of_washrooms",e.target.value)}  class="form-control  py-2" style={{width:"85%"}} aria-describedby="emailHelp"/>
          </Grid>
          <Grid item xs={6} md={3}>
          <label for="exampleInputEmail1" class="form-label labels">Length</label>
          <div class="input-group mb-3">
  <input type="number" value={reg.length} onChange={(e)=>handleChange("length",e.target.value)}  class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"/>
  <div class="input-group-prepend">
    <span class="input-group-text" id="inputGroup-sizing-default">ft</span>
  </div>
</div>  </Grid>
          <Grid item xs={6} md={3}>
          <label for="exampleInputEmail1" class="form-label labels">Speed</label>
          <div class="input-group mb-3">
  <input type="number" value={reg.speed} onChange={(e)=>handleChange("speed",e.target.value)}  class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"/>
  <div class="input-group-prepend">
    <span class="input-group-text" id="inputGroup-sizing-default">Km</span>
  </div>
</div>  </Grid>
<Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">Year of construction</label>
    <input  placeholder="option"  class="form-control  py-2" style={{width:"85%"}} aria-describedby="emailHelp"/>
          </Grid>
          {/* <Grid item xs={12} md={6} >
            <label>Are you a professional?</label>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={age}
                label="select"
                // onChange={handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <label>Is your boat rented with a skipper?</label>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={age}
                label="select"
                // onChange={handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Manufacturer"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Model"
              variant="outlined"
            />
          </Grid> */}
         
          {/* <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Capacity (authorised)"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Length"
              variant="outlined"
            />
          </Grid> */}
          <Grid item xs={12} className="d-flex justify-content-end">
            {load?<CircularProgress />:
            <Button variant="contained" type="submit" >Save</Button>}
          </Grid>
        </Grid>
        </form>
        {/* <Grid container>
            <Grid item xs={12}>
                <h6 className="personal">
                    Photos
                </h6>
                <hr
        style={{ width: "100%", margin: "auto", borderTop: "1px solid grey" }}
      />   
            </Grid>
            <Grid item xs={12} className="mt-5">
                <h6 className="personal">Drag and drop or click here to upload your photos</h6>
                <h6 className="photo">For the cover, choose a photo that shows the whole boat. Then add more photos of the details and the interior.</h6>
                <h6 className="photo">Drag the photos to change the order they appear in</h6>
            </Grid>

            <Grid item xs={12} className="d-flex justify-content-center p-5" style={{border:"1px solid #CED4DA",borderRadius:"8px" }}>
                         <div>
                            <div className="text-center">
                            <img src={camra}/>
                            </div>
                            <h6 className="camra mb-0 text-center">Post your photos here</h6>
                            <h6 className="camra mb-0 text-center">Minimum size: 400x400px</h6>
                            <h6 className="camra mb-0 text-center">Format: jpeg, png, gif</h6>
                            <label htmlFor="fileInput" className="upload mb-0 text-center">upload from your device</label>
                            <input type="file" id="fileInput" hidden />
                         </div>
            </Grid>
            <Grid item xs={12} className="d-flex flex-wrap">
                
                   
                    <div className=" container d-flex flex-wrap justify-content-around">
                      
                <div className="flex-column m-4"  style={{width:"220px",height:"120px",borderRadius:"8px",border:"1px dotted #DEDEDE",display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img src={camraa}/>
                    <h6 className="type">Photo n° 1</h6>
                </div>
                
                </div>
                    <div className=" container d-flex flex-wrap justify-content-around">
                      
                <div className="flex-column m-4"  style={{width:"220px",height:"120px",borderRadius:"8px",border:"1px dotted #DEDEDE",display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img src={camraa}/>
                    <h6 className="type">Photo n° 2</h6>
                </div>
                
                </div>
                    <div className=" container d-flex flex-wrap justify-content-around">
                      
                <div className="flex-column m-4"  style={{width:"220px",height:"120px",borderRadius:"8px",border:"1px dotted #DEDEDE",display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img src={camraa}/>
                    <h6 className="type">Photo n° 3</h6>
                </div>
                
                </div>
            </Grid>
            <Grid item xs={12} className="my-4 d-flex justify-content-end">
                <Button variant="contained">save and continue</Button>
            </Grid>
        </Grid> */}
      </div>
    </>
  );
};

export default Register;
