import React, { useState } from "react";
import shower from "../../assets/asd.png";
import Music from "../../assets/Music.png";
import Web from "../../assets/Weber.png";
import AC from "../../assets/Air Conditioner.png";
import tick from "../../assets/tick.svg";
import { Button, Grid } from "@mui/material";
const Features = ({types}) => {
  const yachtData = {
    // ... (your yacht data here)
    "equipments_outdoor": types.equipments_outdoor,
    "equipments_navigation":types.equipments_navigation,
    "equipments_kitchen": types.equipments_kitchen,
    "equipments_onboard_energy": types.equipments_onboard_energy,
    "equipments_water_sports":types.equipments_water_sports,
    "equipments_leisure_activities": types.equipments_leisure_activities,
    "equipments_extra_comforts": types.equipments_extra_comforts,
    // ... (other properties here)
  };
  
  // Extract all equipment arrays and merge them into a single array using spread operator
  const allEquipment = [].concat(...Object.values(yachtData));
  const [displayedItems, setDisplayedItems] = useState(4);
  console.log(allEquipment,";;;");
  return (
    <Grid container className="mt-3">
      <Grid item xs={12} md={6}>
        <h6 className="about">Features</h6>
        {allEquipment.slice(0, displayedItems).map((item)=>
        <div className="d-flex my-4 align-items-center ">
          {/* <img
            src={shower}
            style={{ width: "20px", height: "20px" }}
            className="mx-2"
          /> */}
          <h6 className="mb-0 featureText">{item}</h6>
        </div>)}
        {/* <div className="d-flex my-4  align-items-center ">
          <img
            src={Music}
            style={{ width: "20px", height: "20px" }}
            className="mx-2"
          />
          <h6 className="mb-0 featureText">Music</h6>
        </div>
        <div className="d-flex my-4 align-items-center ">
          <img
            src={AC}
            style={{ width: "20px", height: "20px" }}
            className="mx-2"
          />
          <h6 className="mb-0 featureText">Air conditioning</h6>
        </div>
        <div className="d-flex my-4 align-items-center ">
          <img
            src={Web}
            style={{ width: "20px", height: "20px" }}
            className="mx-2"
          />
          <p className="mb-0 featureText">Bbq area</p>
        </div> */}
        <Button
          className="featurebutton"
          variant="outlined"
          fullWidth
          style={{
            border: "1px solid #CED4DA",
            fontFamily: "inter",
            fontWeight: 600,
            textTransform: "none",
            fontSize: "16px",
            color: "#343A40",
          }}
          onClick={()=>setDisplayedItems(allEquipment.length)}
        >
          View all feature
        </Button>
      </Grid>
    </Grid>
  );
};

export default Features;
