import { Button, Grid, Paper, Rating } from "@mui/material";
import React, { useEffect, useState } from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import { Image } from "primereact/image";
import { Galleria } from "primereact/galleria";
import img from "../../assets/CardImage.png";
import img1 from "../../assets/CardImage.png";
import img2 from "../../assets/CardImage.png";
import star from "../../assets/star.svg";
import tick from "../../assets/tick.svg";
import img3 from "../../assets/Rectangle 1.png";
import img4 from "../../assets/MainImage.png";
import pin from "../../assets/pin.svg";
import captain from "../../assets/captain.svg";
import captainBlue from "../../assets/captainBlue.png";
import blueBed from "../../assets/BlueBed.svg";
import datee from "../../assets/date1.svg"
import captain1 from "../../assets/captain1.svg";
import arrow from "../../assets/Arrow 2.svg";
import pasenger from "../../assets/pasenger.svg";
import CaptainCap from "../../assets/CaptainCap.svg";
import add from "../../assets/addIcon.svg";
import bath from "../../assets/bath.svg";
import "./style.css";
import Features from "./Features";
import Stories from "./Stories";
import Location from "./Location";
import TermCond from "./TermCond";
import HomeCard from "../Home/HomeCard";
import apiClient from "../../Api/apiClient";
import ImagesCard from "./ImagesCard";
import RatingCard from "./RatingCard";
import FeedBack from "./FeedBack";
import YatchCard from "../AllYatch/YatchCard";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BookingModal from "../../components/BookingModal";
import RegisterModal from "../RegisterModal";
import { useSelector } from "react-redux";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
const DetailYatch = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
const navigate=useNavigate()
  // Access the filter from the state

  const type = location.state?.item;
  console.log(type, "kop");
  const [seePricing,setSeePricing]=useState(false)
  const [totalTime,settotalTime]=useState()
  const [yatchs, setYatchs] = useState();
  const [bookingNow, setBookingNow] = useState(false);
  const fetchyatchs = async () => {
    // const result= await apiClient.get("/yatchs/get/popular/listing")
    // await apiClient.get(`/yatchs/by/filter?max_no_of_guests=${value.passenger[0]}&min_no_of_guests=${value.passenger[1]}&min_price_per_hour=${value.price[0]}&max_price_per_hour=${value.price[1]}&min_yatch_length=${value.length[0]}&max_yatch_length=${value.length[1]}&min_motor_power=${value.power[0]}&max_motor_power=${value.power[1]}&min_year=${value.year[0]}&max_year=${value.year[1]}&min_no_of_cabins=${value.cabin[0]}&max_no_of_cabins=${value.cabin[1]}&min_duration=${value.time[0]}&max_duration=${value.time[1]}`);

    const result = await apiClient.get(`/yatchs/by/filter`);
    setYatchs(result.data.yatchs);
    console.log(result);
  };
  useEffect(() => {
    fetchyatchs();
  }, []);
  const [images, setImages] = useState([img3, img4, img3, img4, img3]);
  const responsiveOptions = [
    {
      breakpoint: "991px",
      numVisible: 4,
    },
    {
      breakpoint: "767px",
      numVisible: 3,
    },
    {
      breakpoint: "575px",
      numVisible: 3,
    },
  ];
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [type]);
  const [yatch, setYatch] = useState();
  const fetchYatch = async () => {
    const result = await apiClient.get("/yatchs");
    setYatch(result.data.yatchs.slice(0, 8));
    console.log(result);
  };
  useEffect(() => {
    fetchYatch();
  }, []);

  const itemTemplate = (item) => {
    return (
      <div className="">
        <img
          className=""
          src={item}
          style={{ width: "100%", height: "60%", objectFit: "contain" }}
        />
      </div>
    );
  };

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={item}
        className="img-fluid"
        style={{ objectFit: "contain", maxHeight: "50px", width: "100%" }}
      />
    );
  };
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow1, setModalShow1] = React.useState(false);
  const [values, setValues] = useState([])
  const handleDateChange = (newValues) => {
    const formattedDates = newValues.map((dateObject) =>
      dateObject.format("YYYY-MM-DD")
    );
    const startDate = new Date(formattedDates[0]);
    const endDate = new Date(formattedDates[formattedDates.length-1]);
    const dateArray = [];
  
    for (let currentDate = startDate; currentDate <= endDate; currentDate.setDate(currentDate.getDate() + 1)) {
      dateArray.push(new Date(currentDate));
    }
  
    const formattedDate1s = dateArray.map((dateObject) =>
      dateObject.toISOString().split('T')[0]
    );
   
  console.log(formattedDate1s,"aa")
    setValues(formattedDate1s);
  }
  console.log(values,"kkk")
  const [showFullText, setShowFullText] = useState(false);
  const toggleShowFullText = () => setShowFullText(!showFullText);
  const [time,setTime]=useState({
    pp:"",
    start_date:"",
    end_date:"",
    "check_in_time":"",
    "check_out_time":""
  })
  function getTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  const handleChange=(key,value)=>{
    setTime({...time,[key]:value})
  }
  const calculateTotalHours = (startDateString, startTimeString, endDateString, endTimeString) => {
    const startDate = new Date(`${startDateString}T${startTimeString}`);
    const endDate = new Date(`${endDateString}T${endTimeString}`);
    
    const timeDifference = endDate - startDate;
    const totalHours = timeDifference / (1000 * 60 * 60); // Convert milliseconds to hours
    
    return totalHours;
};
const {isLoggedIn} = useSelector(state=>state.auth);
  const handleSubmit=async(e)=>{
    e.preventDefault()
   

  const startDate = new Date(time.start_date);
  const endDate = new Date(time.end_date);
  if (startDate > endDate) {
    // Handle the error, for example, log a message or show an error to the user
    console.error("Invalid dates: Start date cannot be greater than end date");
    toast.error("Invalid dates: Start date cannot be greater than end date")
    return
  }
  const dateArray = [];

  for (let currentDate = startDate; currentDate <= endDate; currentDate.setDate(currentDate.getDate() + 1)) {
    dateArray.push(new Date(currentDate));
  }

  const formattedDate1s = dateArray.map((dateObject) =>
    dateObject.toISOString().split('T')[0]
  );

console.log(formattedDate1s,"aa2")






    
    const convertTimeStringToMilliseconds = (dateString, timeString) => {
      console.log(dateString,"kkkk1")
      const [hours, minutes] = timeString.split(':');
      const [year, month, day] = dateString.split('-').map(Number);
      
      const date = new Date(year, month - 1, day, parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
      return date.getTime();
  };
  
  const checkInTime = convertTimeStringToMilliseconds(formattedDate1s[0],time.check_in_time);
  const checkOutTime = convertTimeStringToMilliseconds(formattedDate1s[formattedDate1s.length-1],time.check_out_time);
console.log(checkOutTime,checkInTime)  
const timeDifference = checkOutTime - checkInTime;
const totalHours = calculateTotalHours(formattedDate1s[0],time.check_in_time,formattedDate1s[formattedDate1s.length-1],time.check_out_time);
settotalTime(totalHours)
console.log("Time Difference:", timeDifference,totalHours);
if (timeDifference < 60 * 60 * 1000) {
  toast.error("Time difference is less than 1 hour. Cannot proceed with booking.");
 
  return;
}
const Booking={
  dates:formattedDate1s,
  check_in_time:time.check_in_time,
  check_out_time:time.check_out_time,
  "yatchId": type._id ,
"text_message" : "hey , i want to book this yatch!",
price:type.price_per_hour*totalHours,
type:type
}
setBookingNow(Booking)
if(isLoggedIn){setModalShow(true)}else{
  setModalShow1(true)
}

    
    
  }
  return (
    <div className="container mt-5">
      <Grid container className="justify-content-between">
        <Grid item xs={12}>
          <p className="mb-0 tagss">
            Yacht / United Arab Emirates (Dubai ) / Rent a boat in Dubai
          </p>
          <h6 className="title2">{type?.title}</h6>
          <div className="d-flex w-100 mt-1   " style={{ gap: "10px" }}>
            <div className="d-flex mt-1" style={{ gap: "5px" }}>
              <img src={star} />
              <h5 className="yatchData mb-0 " style={{ whiteSpace: "nowrap" }}>
                5.0 (34 bookings)
              </h5>
            </div>
            <div className="d-flex ">
              <div className="d-flex   align-items-center">
                <img src={blueBed} className="mx-1" />
                <h5
                  className="yatchData mb-0 "
                  style={{ whiteSpace: "nowrap" }}
                >
                  4{" "}
                </h5>
              </div>
              <div className="vertical-line"></div>
              <div className="d-flex  align-items-center">
                <img
                  src={captainBlue}
                  className="mx-1"
                  style={{
                    width: "20px",
                    height: "12px",
                    objectFit: "contain",
                  }}
                />
                <h5
                  className="yatchData mb-0 "
                  style={{ whiteSpace: "nowrap" }}
                >
                  With captain
                </h5>
              </div>
            </div>
          </div>
        </Grid>

        <ImagesCard images={type?.images} />
        <Grid container className="my-5 d-flex justify-content-between">
          <Grid item xs={12} md={5.5}>
            <Grid container>
              <Grid
                item
                xs={12}
                className="py-1 my-2 justify-content-around d-flex"
                style={{ border: "1px solid grey", borderRadius: "10px" }}
              >
                <div
                  style={{ borderRight: "1px solid grey" }}
                  className={` w-25 ${isSmallScreen?'p-1':'p-3'} d-flex flex-column `}
                >
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#20A84E",
                      fontFamily: "inter",
                      fontWeight: 700,
                      fontSize: "12px",
                    }}
                  >
                    99%
                  </Button>
                  <div className="d-flex justify-content-center">
                    <p className={"w-75 mb-0 mt-3   text-center Owner"}>
                      Owner’s response rate
                    </p>
                  </div>
                </div>
                <div
                  style={{ borderRight: "1px solid grey" }}
                  className={` w-25 ${isSmallScreen?'p-1':'p-3'} d-flex flex-column `}
                >
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#FFCE31",
                      fontFamily: "inter",
                      fontWeight: 700,
                      fontSize: "12px",
                      color: "#343A40",
                    }}
                  >
                    {type?.cancellation_policy}
                  </Button>
                  <div className="d-flex justify-content-center">
                    <p className="mb-0 mt-3 w-75 Owner">Cancelation policy</p>
                  </div>
                </div>
                <div
                  style={{ borderRight: "1px solid grey" }}
                  className={` w-25 ${isSmallScreen?'p-1':'p-3'} d-flex flex-column `}
                >
                  <div className=" d-flex justify-content-center">
                  <img
                    src={pasenger}
                    style={{ width: "40px", height: "35px" }}
                  />
                  </div>
                  <div className="d-flex justify-content-center">
                    <p className="mb-0 mt-3 w-75 Owner">Up to 12 passengers</p>
                  </div>
                </div>
                <div className="w-25 p-3 d-flex flex-column align-items-center ">
                  <img
                    src={CaptainCap}
                    style={{ width: "40px", height: "35px" }}
                  />
                  <div className="d-flex  justify-content-center">
                    <p className="mb-0 mt-3  Owner">Captained</p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} className="my-3">
                <p className="mb-0 about">About</p>
                <p className="mb-0 aboutText">
                 {type?.description}
                </p>
              </Grid>
              <hr
                style={{
                  width: "100%",
                  margin: "auto",
                  borderTop: "1px solid grey",
                }}
              />
              <Features types={type} />
              <hr
                className="my-5"
                style={{
                  width: "100%",
                  margin: "auto",
                  borderTop: "1px solid grey",
                }}
              />
              <Grid item xs={12} md={9} className="mb-4">
                <h6 className="include mb-5">Included Service</h6>
                <div className="d-flex flex-wrap " style={{ gap: "30px" }}>
                  <div className="d-flex   align-items-center">
                    <img src={captain} className="mx-1" />
                    <h5
                      className="includeText mb-0 "
                      style={{ whiteSpace: "nowrap" }}
                    >
                      With captain
                    </h5>
                  </div>

                  <div className="d-flex  align-items-center">
                    <img src={captain1} className="mx-1" />
                    <h5
                      className="includeText mb-0 "
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {type?.no_of_cabins} Bedroom
                    </h5>
                  </div>

                  <div className="d-flex   align-items-center">
                    <img src={arrow} />
                    <h5 className="includeText mb-0 ml-1"> length</h5>
                  </div>

                  <div className="d-flex  align-items-center">
                    <img src={add} />
                    <h5
                      className="includeText mb-0 ml-1"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      12 guest
                    </h5>
                  </div>

                  <div className="d-flex   align-items-center">
                    <img src={bath} />
                    <h5
                      className="includeText mb-0 ml-1"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {type?.no_of_washrooms} Bathroom
                    </h5>
                  </div>
                </div>
              </Grid>
              <hr
                className="my-4"
                style={{
                  width: "100%",
                  margin: "auto",
                  borderTop: "1px solid grey",
                }}
              />
              <Location item={type} />
              <hr
                style={{
                  marginTop:"50px",
                  width: "100%",
                  margin: "auto",
                  borderTop: "1px solid grey",
                }}
              />
              <Grid item xs={12} className={`mt-3 d-flex  ${isSmallScreen && 'justify-content-center'}`}>
                <div>
                <h6 className="include">Things to know</h6>
                <h6 className="thing my-3  ">Allowed on the yacht</h6>
                <div className="d-flex flex-column ">
                  <div className="d-flex my-1  w-75  justify-content-between align-items-center ">
                    <p className="mb-0 thingText ">Alcohol</p>
                    <img
                      src={tick}
                      style={{ width: "20px", height: "20px" }}
                      className="mx-2"
                    />
                  </div>
                  <div className="d-flex my-1  w-75  justify-content-between align-items-center ">
                    <p className="mb-0 thingText ">Glass Bottles</p>
                    <img
                      src={tick}
                      style={{ width: "20px", height: "20px" }}
                      className="mx-2"
                    />
                  </div>
                  <div className="d-flex my-1  w-75  justify-content-between align-items-center ">
                    <p className="mb-0 thingText ">Kids under 12</p>
                    <img
                      src={tick}
                      style={{ width: "20px", height: "20px" }}
                      className="mx-2"
                    />
                  </div>
                  <div className="d-flex my-1  w-75  justify-content-between align-items-center ">
                    <p className="mb-0 thingText ">Pets</p>
                    <img
                      src={tick}
                      style={{ width: "20px", height: "20px" }}
                      className="mx-2"
                    />
                  </div>
                  <div className="d-flex my-1  w-75  justify-content-between align-items-center ">
                    <p className="mb-0 thingText ">Shoes</p>
                    <img
                      src={tick}
                      style={{ width: "20px", height: "20px" }}
                      className="mx-2"
                    />
                  </div>
                </div>
                </div>
              </Grid>
              <hr
                className="my-5"
                style={{
                  width: "100%",
                  margin: "auto",
                  borderTop: "1px solid grey",
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} className="d-flex justify-content-center">
            <Paper elevation={3} style={{ height: "60vh" }}>
              <form onSubmit={handleSubmit}>
              <div
                className="py-3"
                style={{ backgroundColor: "white", borderRadius: "5px" }}
              >
                <p className="formTitle px-4" style={{}}>
                  Add dates for prices
                </p>
                <div className="px-4">
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <label for="exampleInputEmail1" class="label labell">
                        Start Date
                      </label>
                      {/* <div style={{position:"relative"}}>
                    <DatePicker 
                    required
                    
                    placeholder="select date"
                    style={{"width": "100%",
                      paddingTop: "26px",
                      paddingBottom: "26px",
                      "fontSize": "1rem",

                   }}
      range
      value={values} 
      onChange={handleDateChange}
    /> */}
    {/* <img src={datee} style={{position:"absolute",right:"8px",top:"17px"}}/>
    </div> */}<div className="datee1">
                      <input
                        type="date"
                        required
                        onChange={(e)=>handleChange("start_date",e.target.value)}
                        value={time.start_date}
                        min={getTodayDate()}
                        class="form-control py-3 "
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                   
                      <label for="exampleInputEmail1" class="label labell">
                        Check In
                      </label>
                      <div className="datee2">
                      <input
                        type="time"
                        required
                        value={time.check_in_time}
                        onChange={(e)=>handleChange("check_in_time",e.target.value)}
                        class="form-control py-3"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                   
                      <label for="exampleInputEmail1" class="label labell">
                       End Date
                      </label>
                      <div className="datee1">
                      <input
                        type="date"
                        required
                        onChange={(e)=>handleChange("end_date",e.target.value)}
                        value={time.end_date}
                        min={getTodayDate()}
                        class="form-control py-3 datee"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                   
                      <label for="exampleInputEmail1" class="label labell">
                        Check Out
                      </label>
                      <div className="datee2">
                      <input
                        type="time"
                        required
                        value={time.check_out_time}
                        onChange={(e)=>handleChange("check_out_time",e.target.value)}
                        class="form-control py-3"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                      </div>
                    </Grid>

                    <Grid item xs={12}>
                     
                      <label className="label labell">Number Of Guests</label>
                      <select
                       onChange={(e) => handleChange("pp", e.target.value)}
                       value={time.pp}
                       class="form-selec custom-select   w-100"
                    // style={{ fontSize: "16px", fontWeight: "500" }}
                    placeholder="Duration per hours"
                    aria-label="Default select example"
                      >
                        <option selected>Number Of Guests</option>
                        <option value="1">0 - 10 persons </option>
                        <option value="2">11 - 15 persons</option>
                        <option value="3">16 - 20 persons</option>
                      </select>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                        sx={{
                          marginTop: "20px",
                          height: "50px",
                          textTransform: "capitalize",
                        }}
                        
                      >
                       Booking Now
                      </Button>
                    </Grid>
                    {/* <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                        sx={{
                          marginTop: "20px",
                          height: "50px",
                          textTransform: "capitalize",
                        }}
                      >
                        Request booking
                      </Button>
                    </Grid> */}
                    {/* <Grid item xs={12} className="d-flex justify-content-between">
                    <h6 className="price mb-0">Total Price</h6>
                    <h6 className="price mb-0">$320</h6>
                  </Grid> */}
                    <Grid
                      item
                      xs={12}
                      className="w-75"
                    >
                      {seePricing && <>
                      <div className="d-flex  justify-content-between">
                        <p className="mb-0 fw-bold">Price Per Hour</p>
                        <p className="mb-0">{type?.price_per_hour}</p>
                      </div>
                      <div className="d-flex  justify-content-between">
                        <p className="mb-0 fw-bold">Total Price For 5 hour</p>
                        <p className="mb-0">{5*type?.price_per_hour}</p>
                      </div>
                      <div className="d-flex  justify-content-between">
                        <p className="mb-0 fw-bold">Total Price For 10 hour</p>
                        <p className="mb-0">{10*type?.price_per_hour}</p>
                      </div>
                      </>}
                      <h6 className="priceDtail" style={{cursor:"pointer"}} onClick={()=>setSeePricing(!seePricing)}>See Price Detail</h6>
                    </Grid>
                  </Grid>
                </div>
              </div>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <div style={{marginTop:isSmallScreen ? '180px' :"1px" }}/>
      <div className="w-75">
        <RatingCard />
        <div className="my-4">
          <FeedBack />
        </div>
      </div>
      {/* feature */}

      {/* <div className="mt-4" style={{ borderTop: "1px solid grey" }}>
        <h6 className="title my-2">A Stories from past user</h6>
        <Stories />
      </div> */}

      {/* <TermCond />
      <div>
        <Grid
          spacing={3}
          className="my-5"
          container
          justify="center"
          alignItems="center"
        >
          {yatch?.slice(0, 4).map((item) => (
            <Grid item xs={12} md={3} className="d-flex justify-content-center">
              <HomeCard item={item} />
            </Grid>
          ))}
        </Grid>
      </div> */}
      <p className="my-5 include">Similar yachts</p>
      <Grid className=" d-flex justify-content-center " container spacing={1}>
        {yatchs?.slice(0, 3).map((item) => (
          <Grid
            item
            xs={12}
            sm={6}
            className="  d-flex justify-content-center w-100"
            md={5}
            lg={4}
          >
            <YatchCard item={item} />
          </Grid>
        ))}
      </Grid>
      {modalShow && <BookingModal
     
        show={modalShow}
        type={bookingNow}
        onHide={() => setModalShow(false)}
      />}
       {modalShow1 &&<RegisterModal
        show={modalShow1}
        onHide={() => setModalShow1(false)}
      />}
    </div>
  );
};

export default DetailYatch;
