import { Button, Grid, Rating } from "@mui/material";
import React, { useEffect, useState } from "react";
import up from "../../assets/Up.png";
import sent from "../../assets/sent.svg";
import "./style.css";
import { useLocation, useNavigate } from "react-router-dom";
import apiClient from "../../Api/apiClient";
import { useSelector } from "react-redux";
const Chat = () => {
  const{userId} =useSelector(state => state.auth);
 const [messages,setMessages]=useState([
  { "messageId": 1, "personId": 1, "content": "Hey, how are you?" },
  { "messageId": 2, "personId": 2, "content": "I'm good, thanks! How about you?" },
  { "messageId": 3, "personId": 1, "content": "I'm doing well too. Any plans for the weekend?" },
  { "messageId": 4, "personId": 2, "content": "Not sure yet. Maybe catch a movie. You?" },
  { "messageId": 5, "personId": 1, "content": "That sounds nice. I might go hiking." },
  { "messageId": 6, "personId": 2, "content": "Hiking sounds fun! Where do you plan to go?" }
]
)
let msgO={
  personId:1,
  messageId:2,
  content:""
}
const [message,setMessage]=useState(msgO)
const handleMessage=()=>{
  console.log(message,"sss")
  if(message.content.length<1)
  {
    return
  }
  setMessages(prevMessages => [...prevMessages, message]);
  setMessage(msgO)
}
  const location = useLocation();
  const naviagte=useNavigate()
  const type = location.state?.Booking;
  console.log(type,"aszx")
  const [chatBox,setChatBox]=useState()
  const [openChat,setOpenChat]=useState()
  const getAllChat=async()=>{
    const result =await apiClient.get(`/chats/convos/all/${userId}`)
    if(result.error) return
    setChatBox(result?.data?.chatList )
    console.log(result,"aaaa")
  }
  console.log(openChat,"www")
  const getMessages=async()=>{
    const result =await apiClient.get(`/chats/${userId}/${openChat?.sender?._id}/${openChat?._id}/and/${openChat?.yatch?._id}`)
    // if(result.error) return
    // setChatBox(result?.data?.chatList )
    console.log(result,"aaaa")
  }
  useEffect(()=>{
    getAllChat()
  },[])
  useEffect(()=>{
    getMessages()
  },[openChat])
  return (
    <>
      <div className="container-fluid p-4">
        <Grid container>
          <Grid className="" item xs={3}>
            <p className="mb-4  mshHead">Messages</p>
            {chatBox?.map((item) => (
              <div onClick={()=>setOpenChat(item)}
                className="d-flex py-3"
                style={{ gap: "15px", borderBottom: "0.7px solid grey" }}
              >
                <img
                  src={up}
                  style={{
                    width: "47px",
                    height: "47px",
                    borderRadius: "50px",
                  }}
                />
                <div className="px-2 w-100">
                  <div className="d-flex  justify-content-between align-items-center">
                    <p className="mb-0 msgName">{item?.sender?.username}</p>
                    <p className="mb-0 msgTime">1 h ago</p>
                  </div>
                  {/* <p className="mb-0  msgTitle">62 Feet yacht azmat 2017</p> */}
                  <p className="mb-0 mt-2 msgMsg">
                    {item?.latest_message}
                  </p>
                </div>
              </div>
            ))}
          </Grid>
          <Grid
            item xs={6}
            style={{
              
              border: "0.7px solid grey",
              height: "80vh",
             
            }}
          >
            <div
              className="d-flex p-3"
              style={{ gap: "15px", borderBottom: "0.7px solid grey" }}
            >
              <img
                src={up}
                style={{ width: "47px", height: "47px", borderRadius: "50px" }}
              />
              <div className=" d-flex justify-content-between align-items-center w-100 ">
                <div className=" ">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0 msgName">Amar</p>
                    <Rating
                      style={{ color: "#2D9CDB", fontSize: 14 }}
                      name="no-value"
                      value={3}
                    />
                  </div>
                  <p className="mb-0  msgTitle">35 boats 36 years old Malaga</p>
                </div>
                <p className="mb-0  msgProf">View their profile</p>
              </div>
            </div>
            <div className="" style={{ height: "65vh", overflowY: "scroll",}}>
              {messages.map((item)=>
              <>
           
            <div className={`d-flex ${item.personId ==1&&'justify-content-end' } p-3`}>
              <div className="w-50 test d-flex" style={{ gap: "15px" }}>
                <img
                  src={up}
                  style={{
                    width: "37px",
                    height: "37px",
                    borderRadius: "50px",
                  }}
                />
                <div className="px-2 ">
                  <div
                    className="d-flex  align-items-center"
                    style={{ gap: "10px" }}
                  >
                    <p className="mb-0 msgName">Amar</p>
                    <p className="mb-0 msgTime">1 h ago</p>
                  </div>
                  {/* <p className='mb-0  msgTitle'>62 Feet yacht azmat 2017</p> */}
                  <p className="mb-0  mt-2 chatmsg">
                   {item.content}
                  </p>
                </div>
              </div>
            </div>
            
            </>
            )}
            </div>
            <div className="px-3 d-flex gap-3" style={{position:"absolute",bottom:26}}>
            <input className="p-2 msgMsgChat" value={message.content} onChange={(e)=>setMessage({...message,content:e.target.value})}  placeholder="Write your message here" style={{border:"1px solid grey",width:"540px",borderRadius:"30px"}} />
            <img src={sent} onClick={()=>handleMessage()}/>
            </div>
          </Grid>
          {/* <Grid item xs={3} className="px-3">
            <p className="chatItemTitle">Your request regarding the boat of Amar</p>
            <div className="d-flex " style={{border:"1px solid grey",borderRadius:"10px"}}>
                <img src={up} style={{width:"90px",height:"90px",borderTopLeftRadius:"10px",borderBottomLeftRadius:"10px"}}/>
                <div className="d-flex  w-100 flex-column  justify-content-center align-items-center">
                    <p className="chatcardtitle mb-0">Azmut 62 feet yacht</p>
                    <p className="chatcardsub text-center mb-0">2018 View the listing</p>
                    <p className="chatcardsub text-center mb-0">Marina dubai,dubai,UAE</p>
                </div>
            </div>
            <div className="d-flex w-100 mt-3 justify-content-between align-items-center">
                <p className="listItemChat mb-0">Check- Out</p>
                <p className="listItemChat mb-0">{type?.dates[type?.dates.length-1]+"-"+type?.check_out_time}</p>
            </div>
            <div className="d-flex w-100 mt-3 justify-content-between align-items-center">
                <p className="listItemChat mb-0">Number of Guest</p>
                <p className="listItemChat mb-0">25 People</p>
            </div>
            <div className="d-flex w-100 mt-3 justify-content-between align-items-center">
                <p className="listItemChat mb-0">Rental Price</p>
                <p className="listItemChat mb-0">{type?.price} Aed</p>
            </div>
            <div className="d-flex w-100 mt-3 justify-content-between align-items-center">
                <p className="listItemChat mb-0">Vat 5%</p>
                <p className="listItemChat mb-0">{type?.price*0.05 }Aed</p>
            </div>
            <div className="d-flex w-100 mt-3 justify-content-between align-items-center">
                <p className="listItemChat mb-0">Handing Fees</p>
                <p className="listItemChat mb-0">Free</p>
            </div>
            <div className="mt-3" style={{borderTop:"1px solid black"}}/>
            <div className="d-flex w-100 mt-1 justify-content-between align-items-center">
                <p className="listItemChat mb-0">Total</p>
                <p className="listItemChat mb-0" style={{color:"#246BBC"}}>{type?.price+type.price*0.05} Aed</p>
            </div>
            <div className="d-flex w-100 mt-3 justify-content-between align-items-center">
              <Button variant="contained" style={{backgroundColor:"#246BBC",transform:"none",color:"white"}} onClick={()=>naviagte("/Confirmation",{ state: { type } })} fullWidth>Book</Button>
            </div>

          </Grid> */}
        </Grid>
      </div>
    </>
  );
};

export default Chat;
