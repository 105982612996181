import { Button, CircularProgress, Grid } from '@mui/material'
import React, { useState } from 'react'
import camr from "../../assets/camr.svg"
import camraa from "../../assets/camraa.svg"
import "./style.css";
import { toast } from 'react-toastify';
import apiClient from '../../Api/apiClient';
const Photos = ({data,next}) => {
  const[load,setLoad]=useState(false)
    const [formData1, setFormData1] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImage1, setSelectedImage1] = useState(null);
    const [selectedImage2, setSelectedImage2] = useState(null);
    const [selectedImage3, setSelectedImage3] = useState(null);
    const [selectedImage4, setSelectedImage4] = useState(null);
    const [planimg, setPlanimg] = useState();
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setFormData1([...formData1,file])
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            setSelectedImage(reader.result);
          };
          reader.readAsDataURL(file);
        }
      };
   
    const handleImageChange1= (event) => {
        const file = event.target.files[0];
        setFormData1([...formData1,file])
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            setSelectedImage1(reader.result);
          };
          reader.readAsDataURL(file);
        }
      };
    const handleImageChange2 = (event) => {
        const file = event.target.files[0];
        setFormData1([...formData1,file])
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            setSelectedImage2(reader.result);
          };
          reader.readAsDataURL(file);
        }
      };
    const handleImageChange3 = (event) => {
        const file = event.target.files[0];
        setFormData1([...formData1,file])
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            setSelectedImage3(reader.result);
          };
          reader.readAsDataURL(file);
        }
      };
      const handleImageChange4 = (event) => {
        const file = event.target.files[0];
        setPlanimg(file)
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            setSelectedImage4(reader.result);
          };
          reader.readAsDataURL(file);
        }
      };
         const handleSubmit=async(e)=>{
    e.preventDefault()
    const formData = new FormData();
    for (let i = 0; i < formData1.length; i++) {
        const file = formData1[i];
        formData.append(`images`,formData1[i]);
      }
      formData.append(`yatchId`, data);
      formData.append(`progress`, "40");
      formData.append(`plan_image`, formData1[0]);
      setLoad(true)
      const result =await apiClient.post("/yatchs/create/second-screen",formData)
      setLoad(false)
      if(result.error) return  toast.error("Error")
toast.success("Successfully Submit")
      console.log(result)
     next()
}
  return (
    <>
    <Grid container className='px-3'>
        <form onSubmit={handleSubmit}>
        <Grid item xs={12}  style={{gap:"15px"}}>
            <p className='mb-0 photostext'>Drag and drop or <span style={{color:"#246BBC"}}>click here </span>to upload your photos</p>
            <p className=' photostext1'>For the cover, choose a photo that shows the whole boat. Then add more photos of the details and the interior.</p>
            <p className=' photostext1'>Drag the photos to change the order they appear in</p>
            </Grid>
        <Grid item xs={12} className='  '  style={{border:"1px solid #CED4DA",borderRadius:"8px"}}>
        <label htmlFor="fileInput" className='py-2 d-flex flex-column justify-content-center align-items-center w-100'>
        {selectedImage ? (
          <img src={selectedImage} style={{height:"200px",width:"90%"}} alt="Selected" />
        ) : (
            <>
          <img src={camraa} alt="Camraa" />
       
        <p className='sub mb-0 mt-1'>Post your photos here</p>
        <p className='poto mb-0'>Minimum size: 400x400px</p>
        <p className='poto'>Format: jpeg, png, gif</p>
        <p className='poto1'>Download from your device</p>
        </>
        )}
      </label>
        <input id="fileInput" hidden type='file' onChange={handleImageChange}/>
        </Grid>
        <Grid item xs={12} className=' mt-5 d-flex flex-wrap justify-content-between'>
            <div className=' w-25 d-flex flex-column justify-content-center align-items-center' style={{border:"1px dashed #CED4DA",borderRadius:"6px"}}>
            <label htmlFor="fileInput1" className='py-2 d-flex flex-column justify-content-center align-items-center w-100'>
        {selectedImage1 ? (
          <img src={selectedImage1} style={{height:"100px",width:"90%"}} alt="Selected" />
        ) : (
            <>
          <img src={camr}/>
            <p className='poto2 mb-0 mt-2'>Photo n° 1</p>
        </>
        )}
      </label>
        <input id="fileInput1" hidden type='file' onChange={handleImageChange1}/>
            
           
            </div>
            <div className=' w-25 d-flex flex-column justify-content-center align-items-center' style={{border:"1px dashed #CED4DA",borderRadius:"6px"}}>
            <label htmlFor="fileInput2" className='py-2 d-flex flex-column justify-content-center align-items-center w-100'>
        {selectedImage2 ? (
          <img src={selectedImage2} style={{height:"100px",width:"90%"}} alt="Selected" />
        ) : (
            <>
          <img src={camr}/>
            <p className='poto2 mb-0 mt-2'>Photo n° 2</p>
        </>
        )}
      </label>
        <input id="fileInput2" hidden type='file' onChange={handleImageChange2}/>
            
            </div>
            <div className=' w-25 d-flex flex-column justify-content-center align-items-center' style={{border:"1px dashed #CED4DA",borderRadius:"6px"}}>
            <label htmlFor="fileInput3" className='py-2 d-flex flex-column justify-content-center align-items-center w-100'>
        {selectedImage3 ? (
          <img src={selectedImage3} style={{height:"100px",width:"90%"}} alt="Selected" />
        ) : (
            <>
          <img src={camr}/>
            <p className='poto2 mb-0 mt-2'>Photo n° 3</p>
        </>
        )}
      </label>
        <input id="fileInput3" hidden type='file' onChange={handleImageChange3}/>
            
            </div>
        </Grid>
        <Grid item xs={12} className='mt-4 d-flex justify-content-center'>
        {load?<CircularProgress />:
          
            <Button type='submit'  variant='contained' style={{color:"white",fontFamily:"Poppins",fontWeight:500}}>Save</Button>}
        </Grid>
        <Grid item xs={12} mt={1}  style={{gap:"15px"}}>
            <p className='mb-0 photostext'>Boat plan</p>
            <p className=' poto'>Add boat design plan so renters can project themselves.</p>
           </Grid>
        <Grid item xs={12} className=' d-flex flex-column justify-content-center align-items-center'  style={{border:"1px solid #CED4DA",borderRadius:"8px"}}>
        <label htmlFor="fileInput4" className='py-2 d-flex flex-column justify-content-center align-items-center w-100'>
        {selectedImage4 ? (
          <img src={selectedImage4} style={{height:"200px",width:"90%"}} alt="Selected" />
        ) : (
            <>
          <img src={camraa} alt="Camraa" />
       
        <p className='sub mb-0 mt-1'>Post your photos here</p>
        <p className='poto mb-0'>Minimum size: 400x400px</p>
        <p className='poto'>Format: jpeg, png, gif</p>
        <p className='poto1'>Download from your device</p>
        </>
        )}
      </label>
        <input id="fileInput4" hidden type='file' onChange={handleImageChange4}/>
        </Grid>
        </form>
    </Grid>
    </>
  )
}

export default Photos