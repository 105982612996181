import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import logo from "../../assets/logoimge.png";
import info from "../../assets/info.svg";
import logoFooter from "../../assets/footerLogo.svg";
import imgSoc from "../../assets/socio2.svg";
import imgSoc1 from "../../assets/socio.svg";
import m1 from "../../assets/m1.svg";
import m2 from "../../assets/m2.svg";
import m3 from "../../assets/m3.svg";
import m4 from "../../assets/m4.svg";
import m5 from "../../assets/m5.svg";
import m6 from "../../assets/m6.svg";
import m7 from "../../assets/m7.svg";
import crs from "../../assets/crs.svg";
import googleplay from "../../assets/googleplay.png";
import Group1 from "../../assets/Group 3589.png";
import Group2 from "../../assets/Group 3591.png";
import Group3 from "../../assets/Group 3592.png";
import addpic from "../../assets/addpic.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./style.css";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import fb from "../../assets/fbb.svg";
import linkdin from "../../assets/linkdinn.svg";
import insta from "../../assets/insta.svg";
import brwose from "../../assets/brwose.svg";
import RegisterModal from "../../pages/RegisterModal";
import RegisterMainModal from "../../pages/RegisterMainModal";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { useDispatch, useSelector } from "react-redux";
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import { logout } from "../../redux/counterSlice";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
export default function Nav() {
  const navigate = useNavigate();
  const dispatch=useDispatch()
  const {isLoggedIn} = useSelector(state=>state.auth);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow1, setModalShow1] = React.useState(false);

 
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleAppStoreClick = () => {
    window.open('https://apps.apple.com/uy/app/getmycharter-user-app/id6475268349', '_blank');
  };

  const handleGooglePlayClick = () => {
    window.open('https://play.google.com/store/apps/details?id=com.getmycharter_user', '_blank');
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  console.log(isSmallScreen,"az")
  return (
    <Box sx={{ flexGrow: 1, backgroundColor: "#FFFFFF" }}>
     <nav class="navbar navbar-expand-lg navbar-light bg-light px-3">
 {isSmallScreen && <img src={logo} style={{ width:isSmallScreen?"150px":"200px", marginRight: "15px",cursor:"pointer" }}  onClick={()=>navigate("/")} />
           }
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
         {!isSmallScreen && <div className="d-flex w-100 ">
            <img src={logo} style={{ width:isSmallScreen?"80px":"190px", margin: "15px",cursor:"pointer" }}  onClick={()=>navigate("/")} />
           
          </div>}
          {isLoggedIn?<div className="d-flex   w-75 justify-content-around  align-items-center">
         
        
          <h6 className=" mb-0  NavText" style={{cursor:"pointer"}} onClick={()=>navigate("/yatch-list")} >
             Listing
            </h6>
            <h6 className="d-none mb-0 d-md-flex NavText" style={{cursor:"pointer"}} onClick={() => {isLoggedIn?navigate("/register"):setModalShow(true)}}>
              Register your boat
            </h6>
            <h6 className=" mb-0 d-none mb-0 d-md-flex  NavText" style={{cursor:"pointer"}} onClick={()=>navigate("/booking")} >
             Booking
            </h6>
            {/* <h6 className=" mb-0  NavText" style={{cursor:"pointer"}}  onClick={()=>navigate("/Chat")} >
             Messages
            </h6> */}
            <h6 className="d-none mb-0 d-md-flex mb-0  NavText" style={{cursor:"pointer"}} >
            Help
            </h6>
        
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
          </IconButton>
        
     
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            width:343,
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 78,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div className="d-flex justify-content-end">
        <img src={crs}/>
        </div>
        <MenuItem onClick={handleClose} className="mt-4">
        <h6 className="mb-0 mnuHead" style={{cursor:"pointer"}} onClick={() => {isLoggedIn?navigate("/register"):setModalShow(true)}}>
             Account
            </h6>
        </MenuItem>
        <MenuItem onClick={handleClose} className="mnutext" >
          <div className="d-flex " style={{gap:"10px"}} onClick={() => {isLoggedIn?navigate("/renter-profile"):setModalShow(true)}}>
            <img src={m1}/>
         Edit profile
          </div>
        </MenuItem>
        {isSmallScreen &&
        <MenuItem onClick={handleClose} className="mnutext" >
          <div className="d-flex " style={{gap:"10px"}} onClick={() => {isLoggedIn?navigate("/booking"):setModalShow(true)}}>
            <img src={m1}/>
            <h6 className="mb-0 NavText" style={{cursor:"pointer"}}  >
             Booking
            </h6>
          </div>
        </MenuItem>}
        <MenuItem onClick={handleClose} className="mnutext">
        <div className="d-flex " style={{gap:"10px"}}>
            <img src={m2}/>
         Security
         </div>
        </MenuItem>
        <MenuItem onClick={handleClose} className="mnutext">
        <div className="d-flex " style={{gap:"10px"}}>
            <img src={m3}/>
         Notification
         </div>
        </MenuItem>
       
        <MenuItem onClick={handleClose} className="mt-3">
        <h6 className="mb-0 mnuHead " style={{cursor:"pointer"}} onClick={() => {isLoggedIn?navigate("/register"):setModalShow(true)}}>
        Support & About
            </h6>
        </MenuItem>
        <MenuItem onClick={handleClose} className="mnutext">
        <div className="d-flex " style={{gap:"10px"}}>
            <img src={m4}/>
            Help & Support
         </div>
        </MenuItem>
        <MenuItem onClick={handleClose} className="mnutext">
        <div className="d-flex " style={{gap:"10px"}}>
            <img src={m5}/>
            Terms & Policies
         </div>
        </MenuItem>
        <MenuItem onClick={handleClose} className="mt-3">
        <h6 className="mb-0 mnuHead " style={{cursor:"pointer"}} onClick={() => {isLoggedIn?navigate("/register"):setModalShow(true)}}>
        Actions
            </h6>
        </MenuItem>
        <MenuItem onClick={handleClose} className="mnutext">
        <div className="d-flex " style={{gap:"10px"}}>
            <img src={m6}/>
            Report a problem
         </div>
        </MenuItem>
        <MenuItem onClick={()=>{handleClose()
        dispatch(logout())
        }} className="mnutext">
        <div className="d-flex " style={{gap:"10px"}}>
            <img src={m7}/>
            Log out
         </div>
        </MenuItem>
      </Menu>
          </div>:
          <div className="d-flex  w-75 justify-content-around  align-items-center">
            <div className="d-flex " style={{ gap: "3px" }}>
            <h6 className="d-none mb-0 d-md-flex align-items-center NavText gap-2" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Browse
            <span><img src={brwose} className="d-none mb-0 d-md-flex " /></span>
        </h6>
        <div class="dropdown-menu" style={{left:800}} aria-labelledby="navbarDropdown">
          <a class="dropdown-item" onClick={()=>navigate("/yatch-list")} style={{cursor:"pointer"}} >Sailboat rentals near me</a>
          <a class="dropdown-item" onClick={()=>navigate("/yatch-list")} style={{cursor:"pointer"}} >RIB rentals near me</a>
          <a class="dropdown-item" onClick={()=>navigate("/yatch-list")} style={{cursor:"pointer"}} >Catamaran rentals near me</a>
          <a class="dropdown-item" onClick={()=>navigate("/yatch-list")} style={{cursor:"pointer"}} >Yacht rentals near me</a>
          <a class="dropdown-item" onClick={()=>navigate("/yatch-list")} style={{cursor:"pointer"}} >Jet ski rentals near me</a>
          <a class="dropdown-item" onClick={()=>navigate("/yatch-list")} style={{cursor:"pointer"}} >Motorboat rentals near me</a>
          
        </div>
              {/* <h6 className="d-none mb-0 d-md-flex NavText"> Browse</h6>
              <img src={brwose} className="d-none mb-0 d-md-flex " /> */}
            </div>
            <h6 className="d-none mb-0 d-md-flex NavText" style={{cursor:"pointer"}} onClick={() => {isLoggedIn?navigate("/register"):setModalShow(true)}}>
              Register your boat
            </h6>
            <h6 className="d-none mb-0 d-md-flex NavText"> Help</h6>
            <div style={{display:"flex", gap:"25px"}}>
            <Button
             onClick={() => setModalShow(true)}
              variant="outlined"
              className=" white_space "
              sx={{ textTransform: "capitalize", borderRadius: "10px",fontSize:"16px",fontWeight:500 }}
            >
              Sign in
            </Button>

            {/* <h5  className='mb-0 NavText' style={{marginRight:"20px"}}>Login</h5> */}

            <Button
              
              variant="contained"
              className=" white_space"
              onClick={() => setModalShow1(true)}
              disableElevation={true}
              sx={{
                textTransform: "capitalize",
                color: "white",
                borderRadius: "10px",
                fontSize:"16px",fontWeight:500
              }}
            >
              Sign up
            </Button>
            </div>
          </div>}
       </div>
       </nav>

      <Outlet />

      <footer class=" p-md-4   p-2" style={{backgroundColor:'#FAFBFC'}} >
        <div class=" px-5" >
          <div className="row">
            <div className="col d-flex justify-content-between">
              {/* <div><img src={require("../assets/logo.png")} className="img-fluid logo_img_size"/></div> */}
            </div>
          </div>

          {/* <div class=""><img src={require('../assets/logo.png')}  className="img-fluid" style={{ borderRadius: '8px', width: '13rem' }} alt="Nav"/></div> */}
          <div class="row">
            <div class="col-12 col-lg-12  mt-4 mt-sm-4 mt-md-0 mt-lg-0 mt-xl-0">
              <div className="row justify-sm-content-center">
                <div className="col-6 col-sm-2 style-none">
                  <h5 class="mb-2 footerHeader">About</h5>
                  <h5 className="mb-2 footerMainText">Home</h5>
                  <h5 className="mb-2 footerMainText">Listings</h5>
                  <h5 className="mb-2 footerMainText ">Yacht types</h5>
                  <h5 className="mb-2 footerMainText ">Popular destinations</h5>
                  <h5 className="mb-2 footerMainText ">All manufacturers</h5>
                  <h5 className="mb-2 footerMainText ">Latest yachts</h5>
                  <h5 className="mb-2 footerMainText ">Blog</h5>
                  </div>
                <div className="col-6 col-sm-2 style-none">
                  <h5 class="mb-2 footerHeader ">Experiences</h5>
                  <h5 className="mb-2 footerMainText">Sailboat rentals</h5>
                  <h5 className="mb-2 footerMainText">RIB rentals</h5>
                  <h5 className="mb-2 footerMainText">Catamaran rentals</h5>
                  <h5 className="mb-2 footerMainText">Yacht rentals</h5>
                  <h5 className="mb-2 footerMainText">Jet skis rentals</h5>
                  <h5 className="mb-2 footerMainText">Motorboat rentals</h5>
                </div>
                <div className="col-6 col-sm-2 style-none">
                  <h5 class="mb-2 footerHeader">Destinations</h5>
                  <h5 className="mb-1 footerMainText">Dubai, Marina</h5>
                  <h5 className="mb-1 footerMainText">Abu Dhabi</h5>
                  <h5 className="mb-1 footerMainText">Ras al Khaimah</h5>
                  <h5 className="mb-1 footerMainText">Paris </h5>
                  <h5 className="mb-1 footerMainText">Bahamas </h5>
                </div>
                <div className="col-6 col-sm-2 style-none">
                  <h5 class="mb-2 footerHeader">Support</h5>
                  <h5 className="mb-1 footerMainText">Terms & conditions</h5>
                  <h5 className="mb-1 footerMainText">Privacy policy</h5>
                  <h5 className="mb-1 footerMainText">Contact us</h5>
                  <h5 className="mb-1 footerMainText">Help </h5>
                  <h5 className="mb-1 footerMainText">FAQ</h5>
                </div>
                <div className="col-6 col-sm-3 style-none">
                  <h5 class="mb-2 footerHeader">Follow us</h5>
                  <div className="d-flex w-100 mb-3">
              <img src={Group3} alt="LinkedIn" style={{width:"26px",height:"26px"}} className="mx-2" />
              <img src={Group2} alt="Facebook" style={{width:"26px",height:"26px"}} className="mx-2" />
              <img src={Group1} alt="Other Social" style={{width:"26px",height:"26px"}} className="mx-2" />
            </div>
                  <h5 class="mb-3 footerHeader">Subscribe for news and offers</h5>
                  <div
                    className="d-flex   align-items-center"
                    style={{ borderRadius: "5px", width:"280px",backgroundColor: "#246bbc" }}
                  >
                    <input
                      placeholder="Email Address"
                      className="py-1 px-3 "
                      style={{height:"37px",width:"230px", borderTopLeftRadius: "5px",borderBottomLeftRadius: "5px",fontSize:"12px",fontWeight:400, border: "1px solid #e0e0e0" }}
                    />
                    <div className="d-flex justify-content-center "  style={{ marginInline: "15px",backgroundColor: "#246bbc" }}>
                    <ArrowForwardIosIcon
                      style={{color:"white" }}
                      fontSize="small"
                    />
                    </div>
                  </div>
                  <h5 class="mt-3 footerHeader">Leader in boat charters</h5>
                  <h5 className="footerText" >
                  With more than 50,000 privately owned yachts available for hire with or without a skipper (yachts, sailboats, motorboats, catamarans, RIBs, etc.), Getmycharter is the Dubai leader in peer-to-peer yacht charters. Book your boating trip the easy way!
                  </h5>
                  <div className="d-flex w-100 ">
                  <img  src={addpic} alt="LinkedIn"  className="" onClick={handleAppStoreClick} />
      <img src={googleplay} alt="Facebook"  className="mx-2" onClick={handleGooglePlayClick} />
     
            </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </footer>
      {modalShow &&<RegisterModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />}
      {modalShow1 &&<RegisterMainModal
        show={modalShow1}
        onHide={() => setModalShow1(false)}
      />}
    </Box>
  );
}
