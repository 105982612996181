import React from "react";
import { Button, Grid, Paper, Typography } from "@mui/material";
import img4 from "../../assets/MainImage.png";
import LogoutModal from "../../components/LogoutModal";
const ImagesCard = ({ images }) => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <Grid container spacing={1} className=" mt-2">
      {/* First column with 1 image */}
      <Grid item xs={12} md={6}>
        <Paper>
          {/* Your first image */}
          <img
            src={images[0]}
            alt="Image 1"
            style={{ width: "100%", height: "360px", objectFit: "cover" }}
          />
        </Paper>
      </Grid>

      {/* Second column with 2 images */}
      <Grid item xs={12} md={6} spacing={2} style={{position:"relative"}} className="">
        <Grid container spacing={1}>
          {images?.slice(1, 5).map((item) => (
            <Grid item xs={6}>
              <Paper className="mb-1">
                {/* Your second image */}
                <img
                  src={item}
                  alt="Image 2"
                  style={{
                    borderRadius: "5px",
                    objectFit: "cover",
                    width: "100%",
                    height: "175px",
                    position: "relative",
                  }}
                />
                {images?.length > 4 && (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      fontSize: "12px",
                      position: "absolute",
                      bottom: 10,
                      right: 10,
                      backgroundColor: "white",
                      color: "#246BBC",
                    }}
                    onClick={()=>setModalShow(true)}
                  >
                    View all photos({" +"+images?.length})
                  </Button>
                )}
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Grid>
      {modalShow && <LogoutModal
      images={images}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />}

    </Grid>
  );
};

export default ImagesCard;
