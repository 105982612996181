import React from 'react'
import "./style.css"
import hep from "../../assets/hep.svg"
import hep2 from "../../assets/hep2.svg"
import { Button, Grid, Paper } from '@mui/material'
const HelpSupport = () => {
  return (
    <>
    <div className='container mt-5'>
        <Grid container>
            <Grid item xs={12}>
                <p>Help & Support</p>
            </Grid>
            
            <Grid item xs={12} className='d-flex justify-content-center'>
              <Paper className='p-3' style={{width:"40%"}}>
                <div className='d-flex  align-items-center' style={{gap:"25px"}}>
                  <img src={hep2}/>
                <p className='mb-0 text'>03011624111</p>
                </div>
                </Paper>
            </Grid>
            <Grid item xs={12} className='mt-3 d-flex justify-content-center'>
              <Paper className='p-3' style={{width:"40%"}}>
                <div className='d-flex  align-items-center' style={{gap:"25px"}}>
                  <img src={hep}/>
                <p className='mb-0 text'>info@getmych.com</p>
                </div>
                </Paper>
            </Grid>
            <Grid item xs={12} className='mt-3 d-flex justify-content-center'>
              <div className='' style={{width:"40%"}}>
                <p className='mb-0 text2'>We will respond to you soon</p>
                </div>
            </Grid>
            <Grid item xs={12} className='mt-3 d-flex justify-content-center'>
            <Paper className='p-3' style={{width:"40%"}}>
              <textarea  rows="10" className='w-100 ' style={{border:"none"}}  placeholder='Tell us how we can help you'/>
            </Paper>
            </Grid>
            <Grid item xs={12} className='mt-3 d-flex justify-content-center'>
            <Paper style={{width:"40%"}}>
             <Button variant='contained' fullWidth>Send Message</Button>
              </Paper>
            </Grid>
        </Grid>
    </div>
    </>
  )
}

export default HelpSupport