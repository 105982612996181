import React from 'react'
import "./style.css"
import rec1 from "../../assets/rec1.png"
import rec2 from "../../assets/rec2.png"
import rec3 from "../../assets/rec3.png"
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
const Destination = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate=useNavigate()
const des=[
    {
        img:rec1,
        name:"Dubai,Marina"
    },
    {
        img:rec2,
        name:"Abu dhabi"
    },
    {
        img:rec3,
        name:"Ras Al Khaimah"
    },
    {
        img:rec1,
        name:"Paris"
    },
    {
        img:rec1,
        name:"Dubai,Marina"
    },
    {
        img:rec2,
        name:"Abu dhabi"
    },
    {
        img:rec3,
        name:"Ras Al Khaimah"
    },
    {
        img:rec1,
        name:"Paris"
    },
   
]
  return (
    <>
    <p className='destinationHead my-5 text-center'>Popular <span className='destinationHead1'> Destination</span> </p>
   <div className='d-flex  justify-content-end mt-5  ' style={{marginLeft:isSmallScreen?"10px":"110px"}}>
    <div className='des d-flex  mt-3 '
     style={{gap:"25px",width:"100%",overflowX: "scroll",scrollbarWidth:'none'}}>
    {
        des.map((item,index)=>
        <div className='d-flex flex-column' onClick={() => index === 0 && navigate("/yatch-list")}>
        <div key={index} style={{ position: 'relative' }}>
        <img src={item.img} alt={item.name} />
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: index > 1? 'block' : 'none',
            backgroundColor: 'rgba(36, 107, 188, 0.57)', // Adjust the color and opacity as needed
          }}
         
        >
          <div className='d-flex justify-content-center '>
          <p className='soon w-50' style={{ marginBottom: 0, marginTop: '7rem', textAlign: 'center' }}>Comming Soon</p>
        </div>
        </div>
        
      </div>
        <p className='des' style={{ marginBottom: 0, marginTop: '1rem', textAlign: 'center' }}>{item.name}</p>
      </div>
        )
    }
    </div>
    </div>

    </>
  )
}

export default Destination
