import { Box, Button, CircularProgress, Grid, Popper, Typography } from "@mui/material";
import "./syle.css";
import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import image from "../../assets/arrowDown.png";
import Popover from '@mui/material/Popover';
import YatchCard from "./YatchCard";
import Slider from "@mui/material/Slider";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import apiClient from "../../Api/apiClient";
import { useLocation } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Autocomplete from "react-google-autocomplete";
const AllCardYatch = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const id = open ? 'simple-popover' : undefined;
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);
  // Access the filter from the state
  const filter = location?.state?.filteredFilter;
  const type = location?.state;
  console.log(type, filter, "lplpl");
  let initial={
    price: [0, filter?.price || 0],
    type: type?.selectType || "",
    time: [0, 0],
    passenger: [0, 0],
    captain: "",
    instent: "",
    length: [0, 0],
    power: [0, 0],
    year: [0, 0],
    cabin: [0, 0],
    date:"",
    latitude: filter?.latitude || "",
    longitude: filter?.longitude || "",
    date: filter?.date || "",
    // Add other sliders with their initial values and keys
  }
  const [value, setValue] = React.useState(initial);
  const updateLatLng = (newLat, newLng) => {
    console.log("first")
    setValue((prevReg) => ({
      ...prevReg,
      latitude: newLat,
      longitude: newLng,
    }));
    setAnchorEl(null)
  };
  const filterKeyMapping = {
    passenger: ["max_no_of_guests", "min_no_of_guests"],
    price: ["min_price_per_hour", "max_price_per_hour"],
    length: ["min_yatch_length", "max_yatch_length"],
    power: ["min_motor_power", "max_motor_power"],
    year: ["min_year", "max_year"],
    cabin: ["min_no_of_cabins", "max_no_of_cabins"],
    time: ["min_duration", "max_duration"],
    // Add other sliders with their corresponding filter parameters
  };
  const [yatchs, setYatchs] = useState();
  const [loading, setLoading] = useState(false);
  const handleSliderChange = (key, value) => {
    console.log(key, value, "azsx");
    setValue((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };
  const handleChange = (key, value) => {
    setValue((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
    setAnchorEl1(null);
    setAnchorEl2(null);
  };
  // const result= await apiClient.get("/yatchs/get/popular/listing")
  // await apiClient.get(`/yatchs/by/filter?max_no_of_guests=${value.passenger[0]}&min_no_of_guests=${value.passenger[1]}&min_price_per_hour=${value.price[0]}&max_price_per_hour=${value.price[1]}&min_yatch_length=${value.length[0]}&max_yatch_length=${value.length[1]}&min_motor_power=${value.power[0]}&max_motor_power=${value.power[1]}&min_year=${value.year[0]}&max_year=${value.year[1]}&min_no_of_cabins=${value.cabin[0]}&max_no_of_cabins=${value.cabin[1]}&min_duration=${value.time[0]}&max_duration=${value.time[1]}`);
  // function objectToQueryString(obj) {
  //   const queryString = Object.entries(obj)
  //     .map(([key, value]) => {
  //       if (Array.isArray(value)) {
  //         // Handle array values using the filterKeyMapping
  //         const [minKey, maxKey] = filterKeyMapping[key];
  //         return `${minKey}=${encodeURIComponent(value[0])}&${maxKey}=${encodeURIComponent(value[1])}`;
  //       } else {
  //         // Handle single values
  //         return `${key}=${encodeURIComponent(value)}`;
  //       }
  //     })
  //     .join('&');

  //   return queryString;
  // }
  function objectToQueryString(obj) {
    const queryString = Object.entries(obj)
      .filter(([key, value]) => {
        if (Array.isArray(value)) {
          // Filter out array values where both elements are 0
          return value[0] !== 0 || value[1] !== 0;
        } else {
          // Filter out single values where they are 0, null, or empty string
          return value !== 0 && value !== null && value !== "";
        }
      })
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          const [minKey, maxKey] = filterKeyMapping[key];
          return `${minKey}=${encodeURIComponent(
            value[0]
          )}&${maxKey}=${encodeURIComponent(value[1])}`;
        } else {
          return `${key}=${encodeURIComponent(value)}`;
        }
      })
      .join("&");

    return queryString;
  }
  const yatch = async () => {
    const queryString = objectToQueryString(value);
    console.log(queryString, "ppppp");
    setLoading(true);
    const result = await apiClient.get(`/yatchs/by/filter?${queryString}`);
    setLoading(false);
    setYatchs(result.data.yatchs);
    console.log(result);
  };
  useEffect(() => {
    console.log(value,"aa1")
    yatch();
  }, [value]);

  return (
    <>
      <div className="container-fluid my-5">
        <Paper
          className="py-2"
          style={{
            boxShadow: "0 0 4px rgba(0, 0, 0, 0.08)",
            width: "95%",
            margin: "0 auto",
          }}
        >
          <Grid container className="d-flex">
            <Grid
              item
              xs={6}
              md={4}
              className="d-flex flex-column align-items-start px-4"
            >
              <div className="mb-2"  aria-describedby={id} onClick={handleClick}>
                <div className="d-flex align-items-center gap-1">
                  <p className="mb-0 barMain">Location</p>
                  <img
                    src={image}
                    className=""
                    style={{ width: "10px", height: "5px" }}
                  />
                </div>
                <div className="">
        <Autocomplete
        placeholder="select city"
                  class=" w-100 "
                  style={{ width: "85%",border:"none" }}
                  apiKey="AIzaSyCnFD6bP1GG63UwYt375dHPE5KZ3QxGVY8"
                  onPlaceSelected={(place) => {
                    const lat = place.geometry.location.lat();
                    const lng = place.geometry.location.lng();
                    updateLatLng(lat, lng);

                    console.log("Latitude:", lat);
                    console.log("Longitude:", lng);
                    console.log(place);
                  }}
                />
                </div>
              </div>
              {/* <Popper style={{height:"300px"}}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
      <div className="p-3">
        <Autocomplete
        placeholder="select city"
                  class="form-control w-100 py-2"
                  style={{ width: "85%" }}
                  apiKey="AIzaSyCnFD6bP1GG63UwYt375dHPE5KZ3QxGVY8"
                  onPlaceSelected={(place) => {
                    const lat = place.geometry.location.lat();
                    const lng = place.geometry.location.lng();
                    updateLatLng(lat, lng);

                    console.log("Latitude:", lat);
                    console.log("Longitude:", lng);
                    console.log(place);
                  }}
                />
                </div>
               
      </Popper> */}
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
              className={`d-flex flex-column align-items-start  w-100 px-4 ${isSmallScreen && 'align-items-center'}`}
            >
               <div className="mb-2"  aria-describedby={id} onClick={handleClick1}>
                <div className="d-flex align-items-center gap-1">
                  <h6 className="mb-0 barMain">Date</h6>
                  <img
                    src={image}
                    className=""
                    style={{ width: "10px", height: "5px" }}
                  />
                </div>
                <div >
       <input
                    onChange={(e) => handleChange("date", e.target.value)}
                    // value={}
                    type="date"
                   
                    style={{ color: "#606060",border:"none"}}
                    placeholder="Add your dates"
                    className=""
                  />
       </div>
              </div>
              {/* <Popper style={{height:"300px"}}
        id={id}
        open={open1}
        anchorEl={anchorEl1}
        onClose={handleClose1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
       <div>
       <input
                    onChange={(e) => handleChange("date", e.target.value)}
                    // value={}
                    type="date"
                   
                    style={{ color: "#606060" }}
                    placeholder="Add your dates"
                    class="form-control duration"
                  />
       </div>
      </Popper> */}
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              className={` d-flex flex-column align-items-start px-4 ${isSmallScreen && 'mt-3'} ${isSmallScreen && 'align-items-center'}`}
            >
             <div className="mb-2"  aria-describedby={id} onClick={handleClick2}>
                <div className="d-flex align-items-center gap-1">
                  <h6 className="mb-0 barMain">Duration per hour</h6>
                  <img
                    src={image}
                    className=""
                    style={{ width: "10px", height: "5px" }}
                  />
                </div>
                <div>
       <select
                    onChange={(e) => handleChange("prices", e.target.value)}
                    // value={filter.price}
                    class=""
                    style={{ border:"none",appearance:"none" }}
                    placeholder="Duration per hours"
                    aria-label="Default select example"
                  >
                    <option selected>Duration per hours</option>
                    <option value="500">500 AED</option>
                    <option value="800">800 AED</option>
                    <option value="1200">1200 AED</option>
                  </select>
       </div>
              </div>
              {/* <Popper style={{height:"300px"}}
        id={id}
        open={open2}
        anchorEl={anchorEl2}
        onClose={handleClose2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
       <div>
       <select
                    onChange={(e) => handleChange("prices", e.target.value)}
                    // value={filter.price}
                    class="form-selec custom-select duration  w-100"
                    style={{ fontSize: "16px", fontWeight: "500" }}
                    placeholder="Duration per hours"
                    aria-label="Default select example"
                  >
                    <option selected>Duration per hours</option>
                    <option value="500">500 AED</option>
                    <option value="800">800 AED</option>
                    <option value="1200">1200 AED</option>
                  </select>
       </div>
      </Popper> */}
            </Grid>
          </Grid>
        </Paper>
        <div className="mt-3 px-5 d-flex justify-content-end">
          <Button onClick={()=>setValue(initial)}>Reset Filter</Button>
        </div>
      </div>
      <div className="container-fluid px-4">
        <Grid
          container
          className="d-flex"
          style={{ width: "95%", margin: "0 auto" }}
        >
          <Grid
            item
            xs={12}
            md={2}
            className="d-flex "
            style={{ width: "30px" }}
          >
            <div>
              <div className="mt-2">
                <h6 className="filterHeading">Price per hour ($)</h6>
                <Box
                  sx={{
                    width: "90%",
                  }}
                >
                  <Slider
                    style={{ width: "100%" }}
                    max={1200}
                    // getAriaLabel={() => 'Temperature range'}
                    value={value.price}
                    onChange={(event, newValue) =>
                      handleSliderChange("price", newValue)
                    }
                    marks={[
                      {
                        value: 0,
                        label: "$ 0",
                      },

                      {
                        value: 1200,
                        label: "$ 1200+",
                      },
                    ]}
                    valueLabelDisplay="auto"
                  />
                </Box>
                <div
                  className="my-3"
                  style={{
                    width: "90%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.20)",
                  }}
                />
              </div>
              <div className="mt-2">
                <h6 className="filterHeading">Type</h6>
                <Box
                  className="d-flex pb-3  flex-wrap"
                  sx={{
                    width: "95%",
                  }}
                >
                  <Button
                    className="m-1"
                    style={{ fontSize: "0.5rem", padding: "4px 7px" }}
                    variant="outlined"
                    size="small"
                  >
                    Yacht
                  </Button>
                  <Button
                    className="m-1"
                    style={{ fontSize: "0.5rem", padding: "4px 7px" }}
                    variant="outlined"
                    size="small"
                  >
                    Catamaran
                  </Button>
                  <Button
                    className="m-1"
                    style={{ fontSize: "0.5rem", padding: "4px 7px" }}
                    variant="outlined"
                    size="small"
                  >
                    JetSki
                  </Button>
                  <Button
                    className="m-1"
                    style={{ fontSize: "0.5rem", padding: "4px 7px" }}
                    variant="outlined"
                    size="small"
                  >
                    Moterboat
                  </Button>
                  <Button
                    className="m-1"
                    style={{ fontSize: "0.5rem", padding: "4px 7px" }}
                    variant="outlined"
                    size="small"
                  >
                    RIB
                  </Button>
                  <Button
                    className="m-1"
                    style={{ fontSize: "0.5rem", padding: "4px 7px" }}
                    variant="outlined"
                    size="small"
                  >
                    Sailboat
                  </Button>
                  <Button
                    className="m-1"
                    style={{ fontSize: "0.5rem", padding: "4px 7px" }}
                    variant="outlined"
                    size="small"
                  >
                    Motorboat
                  </Button>
                </Box>
                <div
                  className="my-3"
                  style={{
                    width: "90%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.20)",
                  }}
                />
              </div>
              <div className="mt-2">
                <h6 className="filterHeading">Duration (Time)</h6>
                <Box
                  sx={{
                    width: "90%",
                  }}
                >
                  <Slider
                    valueLabelDisplay="auto"
                    style={{ width: "100%" }}
                    defaultValue={[5, 11]}
                    max={12}
                    value={value.time}
                    onChange={(event, newValue) =>
                      handleSliderChange("time", newValue)
                    }
                    marks={[
                      {
                        value: 0,
                        label: "1 hr",
                      },

                      {
                        value: 12,
                        label: "12hr",
                      },
                    ]}
                  />
                </Box>
                <div
                  className="my-3"
                  style={{
                    width: "90%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.20)",
                  }}
                />
              </div>
              <div className="mt-2">
                <h6 className="filterHeading">Number of passengers</h6>
                <Box
                  sx={{
                    width: "90%",
                  }}
                >
                  <Slider
                    style={{ width: "100%" }}
                    max={22}
                    getAriaLabel={() => "Temperature range"}
                    value={value.passenger}
                    onChange={(event, newValue) =>
                      handleSliderChange("passenger", newValue)
                    }
                    defaultValue={[2, 8]}
                    marks={[
                      {
                        value: 0,
                        label: "0",
                      },

                      {
                        value: 22,
                        label: "20+",
                      },
                    ]}
                    valueLabelDisplay="auto"
                  />
                </Box>
                <div
                  className="my-3"
                  style={{
                    width: "90%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.20)",
                  }}
                />
              </div>
              <div className="mt-2">
                <h6 className="filterHeading">Captain</h6>
                <Box
                  className="d-flex pb-3 gap-2 flex-wrap"
                  sx={{
                    width: "100%",
                  }}
                >
                  <FormControl size="small">
                    {/* <FormLabel className='filterHeading' id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="captain"
                        control={<Radio />}
                        label="With captain"
                        style={{ fontSize: "12px", fontFamily: "inter" }}
                      />
                      <FormControlLabel
                        value="nocaptain"
                        control={<Radio />}
                        label="No captain"
                        style={{ fontSize: "12px" }}
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <div
                  className="my-3"
                  style={{
                    width: "90%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.20)",
                  }}
                />
              </div>
              <div className="mt-2">
                <h6 className="filterHeading">Instant book</h6>
                <Box
                  className="d-flex pb-3 gap-2 flex-wrap"
                  sx={{
                    width: "100%",
                  }}
                >
                  <FormControl size="small">
                    {/* <FormLabel className='filterHeading' id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                        style={{ fontSize: "12px" }}
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No "
                        style={{ fontSize: "12px" }}
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <div
                  className="my-3"
                  style={{
                    width: "90%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.20)",
                  }}
                />
              </div>
              <div className="mt-2">
                <h6 className="filterHeading">Yacht Length (ft)</h6>
                <Box
                  sx={{
                    width: "90%",
                  }}
                >
                  <Slider
                    style={{ width: "100%" }}
                    max={72}
                    getAriaLabel={() => "Temperature range"}
                    value={value.length}
                    defaultValue={[20, 55]}
                    onChange={(event, newValue) =>
                      handleSliderChange("length", newValue)
                    }
                    marks={[
                      {
                        value: 0,
                        label: "0",
                      },

                      {
                        value: 22,
                        label: "70m+",
                      },
                    ]}
                    valueLabelDisplay="auto"
                  />
                </Box>
                <div
                  className="my-3"
                  style={{
                    width: "90%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.20)",
                  }}
                />
              </div>
              <div className="mt-2">
                <h6 className="filterHeading">Motor Power (hp)</h6>
                <Box
                  sx={{
                    width: "90%",
                  }}
                >
                  <Slider
                    max={251}
                    style={{ width: "100%" }}
                    value={value.power}
                    onChange={(event, newValue) =>
                      handleSliderChange("power", newValue)
                    }
                    defaultValue={[50, 118]}
                    marks={[
                      {
                        value: 0,
                        label: "0",
                      },

                      {
                        value: 251,
                        label: "250 hp+",
                      },
                    ]}
                    valueLabelDisplay="auto"
                  />
                </Box>
                <div
                  className="my-3"
                  style={{
                    width: "90%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.20)",
                  }}
                />
              </div>
              <div className="mt-2">
                <h6 className="filterHeading">Year</h6>
                <Box
                  sx={{
                    width: "90%",
                  }}
                >
                  <Slider
                    max={2023}
                    style={{ width: "100%" }}
                    min={1990}
                    getAriaLabel={() => "Year range"}
                    value={value.year}
                    defaultValue={[2008, 2018]}
                    onChange={(event, newValue) =>
                      handleSliderChange("year", newValue)
                    }
                    marks={[
                      {
                        value: 1980,
                        label: "1980",
                      },
                      {
                        value: 2023,
                        label: "2023",
                      },
                    ]}
                    valueLabelDisplay="auto"
                  />
                </Box>
                <div
                  className="my-3"
                  style={{
                    width: "90%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.20)",
                  }}
                />
              </div>
              <div className="mt-2">
                <h6 className="filterHeading">Number of cabins</h6>
                <Box
                  sx={{
                    width: "90%",
                  }}
                >
                  <Slider
                    max={13}
                    getAriaLabel={() => "Year range"}
                    value={value.cabin}
                    defaultValue={[2, 8]}
                    onChange={(event, newValue) =>
                      handleSliderChange("cabin", newValue)
                    }
                    marks={[
                      {
                        value: 0,
                        label: "0",
                      },
                      {
                        value: 13,
                        label: "12",
                      },
                    ]}
                    valueLabelDisplay="auto"
                  />
                </Box>
                <div
                  className="my-3"
                  style={{
                    width: "90%",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.20)",
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} justifyContent={"center"} md={9.5} className="">
            <Grid className={isSmallScreen ? 'ml-0' : 'ml-5'} container spacing={1}>
              {loading ? (
                <div
                  className=" d-flex  w-100  justify-content-center align-items-center"
                  style={{ height: "300px" }}
                >
                  <CircularProgress />
                </div>
              ) : (
                yatchs?.map((item) => (
                  <Grid item xs={12} sm={6} className=" d-flex justify-content-center  w-100" md={5} lg={4}>
                    <YatchCard test={isSmallScreen?"250px":"370px"} item={item} />
                  </Grid>
                ))
              )}
              {!loading &&(yatchs?.length==0) && <div className=" w-100 d-flex justify-content-center">
                <p style={{fontWeight:"600",fontSize:"22px"}}>No yatch Available</p>
              </div> }
              <Grid item xs={12} className="d-flex justify-content-center my-3">
                <Button
                  className="exploreMore"
                  style={{
                    fontFamily: "poppins1",
                    height: "50px ",
                    backgroundColor: "#246BBC",
                  }}
                  variant="contained"
                >
                  Explore More
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default AllCardYatch;
