import { Button, Grid } from '@mui/material'
import React from 'react'
import arw from "../../assets/arw.svg"
import gal from "../../assets/gal.svg"
import camr1 from "../../assets/camr1.svg"
const Report = () => {
  return (
   <div className='container d-flex justify-content-center mt-5 '>
    <Grid container style={{width:"40%"}}>
            <Grid item xs={12}  className=' d-flex justify-content-center'>
                <p className='mb-0 text3'>Report a problem</p>
            </Grid>
            <Grid item xs={12}  className=' d-flex mt-4 '>
                <p className='mb-0 text4'>help@getmycharter.com</p>
            </Grid>
            <Grid item xs={12}  className=' d-flex mt-2 justify-content-between '>
            <textarea className='w-100' placeholder='Tell us how we can help you' style={{border:"1px solid grey",borderRadius:"10px"}} rows="10"/>
            </Grid>
          
            <Grid item xs={12} style={{borderTop:"1px solid #246BBC",borderBottom:"1px solid #246BBC"}}  className=' d-flex p-3 mt-2 justify-content-between '>
                <div className='d-flex align-items-center ' style={{gap:"15px"}}>
                <img src={camr1}/>
                <p className='mb-0 text7'>Take a screenshot </p>
                </div>
                {/* <img src={arw}/> */}
            </Grid>
            <Grid item xs={12} style={{borderBottom:"1px solid #246BBC"}}  className=' d-flex p-3 justify-content-between '>
            <div className='d-flex align-items-center ' style={{gap:"15px"}}>
                <img src={gal} />
                <p className='mb-0 text7' >Select image from gallery </p>
                </div>
                {/* <img src={arw}/> */}
            </Grid>
            <Grid item xs={12} className='mt-4'>
                <Button variant='outlined' style={{fontSize:16,fontWeight:600}} fullWidth>Submit Report</Button>
            </Grid>
    </Grid>
   </div>
  )
}

export default Report