import { Box, Button, Grid, LinearProgress, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import img1 from "../../assets/setting.svg"
import img2 from "../../assets/Icon (2).svg"
import img3 from "../../assets/Icon (3).svg"
import img4 from "../../assets/Icon (4).svg"
import ticks from "../../assets/ticks.svg"
import equp from "../../assets/equp.svg"
import calender from "../../assets/calender.svg"
import cancel from "../../assets/cancel.svg"
import set from "../../assets/set.svg"
import need from "../../assets/need.svg"
import "./style.css";
import Photos from './Photos'
import Price from './Price'
import Cancellation from './Cancellation'
import Calender from './Calender'
import Equipment from './Equipment'
import { useLocation } from 'react-router-dom'
const RegisterStep2 = () => {
  const location = useLocation();
  const [validation,setValidation]=useState([1,2])
const data = location.state;
console.log(validation,"lllllll")
  const [step,setStep]=useState(1)
  const[progress,setProgress]=useState(30)
  const next=()=>{
    if(step>4) return
    setStep(step+1)
    if(step+1==3){
    setValidation([...validation,3])}
    if(step+1==4){
      setValidation([...validation,4])}
  }
  useEffect(()=>{
    if(step==1){
      setProgress(30)
    }
    if(step==2){
      setProgress(60)
    }
    if(step==3){
      setProgress(80)
    }
    if(step==4){
      setProgress(100)
    }
  
  },[step])
  return (
    <div className="container mt-4">
      
    <Grid item container>
      <Grid item xs={12}>
        <h6 className="head">Register your boat</h6>
      </Grid>
     
      <Grid item sm={12} md={4}  style={{borderRadius: "3px",border: "1px solid #E6E6E6"}}>
        <div className='px-2 mt-3'  style={{borderBottom: "1px solid #E6E6E6"}}>
        <h5 className='step2Side text-center'>Ahoy there Captain!</h5>
        <p className='steptext text-left'>Your yacht will soon be visible to the largest community of sailors interested in peer-to-peer yacht charters. Welcome to Get my charter</p>
        </div>
        <div className='px-2 mt-3'  style={{borderBottom: "1px solid #E6E6E6"}}>
       
     
      <Box  sx={{  display: 'flex',justifyContent:"space-between", alignItems: 'center' }}>
        <p className='stepProgress mb-0' variant="body2" color="text.secondary">Progress </p>
        <p  className='stepProgress1 mb-0' variant="body2" color="text.secondary">{progress}%</p>
      </Box>
      <Box mb={3} sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      
    </Box>

    </div>
    <div className='d-flex justify-content-between px-3 mt-3'>
    <div className='d-flex ' style={{gap:"20px"}}>
<img src={set}/>
<p className='mb-0 sidebarText'>General</p>
    </div>
<img src={ticks}/>
    </div>
    <div className='d-flex justify-content-between  px-3 mt-3 '>
    <div className='d-flex ' style={{gap:"20px"}}>
<img src={img1}/>
<p className='mb-0 sidebarText'>Description</p>
    </div>
<img src={ticks}/>
    </div>
    <div className='d-flex justify-content-between  px-3 mt-3 '>
    <div className='d-flex ' style={{gap:"20px"}}>
<img src={img2}/>
<p className='mb-0 sidebarText'>Photos</p>
    </div>
    {validation.includes(2)&&
<img src={ticks}/>}
    </div>
    <div className='d-flex justify-content-between  px-3 mt-3 '>
    <div className='d-flex ' style={{gap:"20px"}}>
<img src={img3}/>
<p className='mb-0 sidebarText'>Pricing</p>
    </div>
    {validation.includes(3)&&
<img src={ticks}/>}
    </div>
    <div className='d-flex justify-content-between  px-3 mt-3 '>
    <div className='d-flex ' style={{gap:"20px"}}>
<img src={cancel}/>
<p className='mb-0 sidebarText'>Cancellation Policy</p>
    </div>
    {validation.includes(4)&&
<img src={ticks}/>}
    </div>
    {/* <div className='d-flex justify-content-between  px-3 mt-3 '> */}
    {/* <div className='d-flex ' style={{gap:"20px"}}>
<img src={calender}/>
<p className='mb-0 sidebarText'>Calender</p>
    </div>
<img src={ticks}/>
    </div> */}
    <div className='d-flex justify-content-between  px-3 mt-3 '>
    <div className='d-flex ' style={{gap:"20px"}}>
<img src={equp}/>
<p className='mb-0 sidebarText'>Equipment</p>
    </div>
    {validation.includes(4)&&
<img src={ticks}/>}
    </div>
    
    <div className='px-3 ' style={{marginTop:"100px",marginBottom:"60px"}}>
    <div className='d-flex ' style={{gap:"20px"}}>
<img src={need}/>
<p className='mb-0 sidebarText' style={{fontSize:"24px"}}>Need some help?</p>
    </div>
   
<p className='mb-0 sidebarText' style={{fontSize:"16px"}}>by telephone:<span style={{color:"#246BBC"}}> +44 1924 921 020 </span></p>
    

    </div>
   
      </Grid>
      <Grid item sm={12} md={8}>
      <Grid item xs={12} className='d-flex justify-content-end ' style={{gap:"15px"}}>
            <Button variant='outlined'  style={{color:"#246BBC",fontFamily:"Poppins",fontWeight:500}}>Calender</Button>
            <Button variant='contained'  style={{color:"white",fontFamily:"Poppins",fontWeight:500}}>Preview Listing</Button>
        </Grid>
      {step==1 &&<Photos data={data} next={next}/>}
      {step==2 &&<Price data={data} next={next}/>}
      {step==3 &&<Cancellation data={data} next={next}/>}
      {/* {step==4 &&<Calender next={next}/>} */}
      {step==4 &&<Equipment data={data} next={next}/>}
      </Grid>
      </Grid>
      </div>
  )
}

export default RegisterStep2