import React from "react";
import Nav from "./components/Nav/Nav";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import AllCardYatch from "./pages/AllYatch/AllCardYatch";
import DetailYatch from "./pages/DetailYatch/DetailYatch";
import Register from "./pages/Register/Register";
import RegisterStep2 from "./pages/Register/RegisterStep2";
import Booking from "./pages/Booking/Booking";
import Chat from "./pages/Chat/Chat";
import Confrimation from "./pages/Chat/Confrimation";
import OwnerProfile from "./pages/OwnerProfile/OwnerProfile";
import Profile from "./pages/RenterProfile/Profile";
import ProtectedRoute from "./components/ProtectedRoute";
import HelpSupport from "./pages/supprt/HelpSupport";
import Security from "./pages/supprt/Security";
import Report from "./pages/supprt/Report";
import TermPolicy from "./pages/supprt/TermPolicy";
import Profiles from "./pages/OwnerProfile/Profile";

function App(props) {
  return (
    <BrowserRouter>
      <Routes>
      <Route  element={<Nav />}>
      <Route
          path="/"
          element={<Home />}
        />
      <Route
          path="/yatch-list"
          element={<AllCardYatch />}
        />
      <Route
          path="/yatch-detail"
          element={<DetailYatch />}
        />
         {/* <Route element={<ProtectedRoute />}> */}
      <Route
          path="/register"
          element={<ProtectedRoute><Register /></ProtectedRoute>}
        />
      <Route
          path="/register2"
          element={<ProtectedRoute><RegisterStep2 /></ProtectedRoute>}
        />
    
      <Route
          path="/Booking"
          element={<ProtectedRoute><Booking /></ProtectedRoute>}
        />
      <Route
          path="/Chat"
          element={<ProtectedRoute><Chat /></ProtectedRoute>}
        />
      <Route
          path="/Confirmation"
          element={<ProtectedRoute><Confrimation /></ProtectedRoute>}
        />
      <Route
          path="/help"
          element={<ProtectedRoute><HelpSupport /></ProtectedRoute>}
        />
      <Route
          path="/profile"
          element={<ProtectedRoute><Profiles /></ProtectedRoute>}
        />
      <Route
          path="/securty"
          element={<ProtectedRoute><Security /></ProtectedRoute>}
        />
      <Route
          path="/report"
          element={<ProtectedRoute><Report /></ProtectedRoute>}
        />
      <Route
          path="/ownerProfile"
          element={<ProtectedRoute><OwnerProfile /></ProtectedRoute>}
        />
      <Route
          path="/renter-profile"
          element={<ProtectedRoute><Profile /></ProtectedRoute>}
        />
      <Route
          path="/term"
          element={<ProtectedRoute><TermPolicy /></ProtectedRoute>}
        />
        </Route>
        {/* </Route> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
