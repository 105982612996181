import { Grid } from '@mui/material'
import React from 'react'
import adsimage from "../../assets/adsImage.png"

const Ads = () => {
  return (
    <Grid container justifyContent="space-between " className='px-3'>
        <Grid item xs={12} md={6} className=' d-flex justify-content-center flex-column align-items-center'>
        <p className='adsbook1 w-75  my-5 text-center'>
        Find & <span 
        className='adsbook'> book</span> boat rentals, yachts, jet skis, sailboats & much more!
        </p>
        <p className='adbanner w-75 my-2 text-center '>
        Embark on unforgettable adventures with Getmycharter, your premier destination for finding and booking the perfect watercraft. Whether you crave the thrill of jet skis, the luxury of yachts, the serenity of sailboats, or the freedom of boat rentals, we have your aquatic dreams covered. 
        </p>
        </Grid>
        <Grid item xs={12} md={6} >
            <img src={adsimage}  style={{width:"100%",height:"auto"}}/>
        </Grid>
    </Grid>
  )
}

export default Ads