import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { InputText } from "primereact/inputtext";
import "./../styles/main.css";
import img1 from "../assets/11.png";
import img2 from "../assets/12.png";
import img3 from "../assets/13.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box, Button, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import apiClient from "../Api/apiClient";
import { useDispatch } from "react-redux";
import { login } from "../redux/counterSlice";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useGoogleLogin } from '@react-oauth/google';
import axios from "axios";  
function RegisterModal(props) {
  const [selectedOption, setSelectedOption] = useState("renter");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const loginGoogle = useGoogleLogin({
    onSuccess: async(tokenResponse) =>{ console.log( tokenResponse)
  
        
    const re=tokenResponse.access_token
    
   
    
     const result= await apiClient.post("users/login/google",{
      "idToken":re,
      "user_type" : selectedOption ,
      "device_push_token" : "fcm token"
    }
   
    )
   
    }
  
  

   
  });
  
  
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
 
  let initialState = {
    email: "",
    password: "",
    user_type: selectedOption,
  };
  const [showPassword, setShowPassword] = useState(false);
  const [logins, setLogin] = useState(initialState);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };
  const handleChange = (key, value) => {
    setLogin({ ...logins, [key]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);
    const result = await apiClient.post(
      `/users${logins.user_type === "owner" ? "/owner" : ""}/login`,
      logins
    );
    setLoad(false);
    if (result.error) return toast.error("Error");
    toast.success("Successfully Login");
    console.log(result);
    dispatch(
      login({ token: result?.data?.token,userId:result?.data?.userId, userType: result?.data?.user_type })
    );
    props.onHide();
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ borderRadius: "30px" }}
    >
      <Modal.Body className={`${isSmallScreen?'px-2':'px-5'}`}>



     



        <form onSubmit={handleSubmit}>
          <div className={`${isSmallScreen?'px-1':'px-5'} mt-2 py-3 d-flex justify-content-between align-items-center`}>
            <p
              className={`py-2 ${
                selectedOption === "renter" ? "Sign1" : "Sign"
              }`}
              style={{
                cursor: "pointer",
                  fontSize:isSmallScreen && 12,
                borderBottom:
                  selectedOption === "renter" && "3px solid #246BBC",
              }}
              onClick={() => {
                handleChange("user_type", "renter");
                handleOptionClick("renter");
              }}
            >
              Sign in as Renter
            </p>
            <p
              className={`py-2 ${
                selectedOption === "owner" ? "Sign1" : "Sign"
              }`}
              style={{
                cursor: "pointer",
                fontSize:isSmallScreen && 12,
                borderBottom: selectedOption === "owner" && "3px solid #246BBC",
              }}
              onClick={() => {
                handleChange("user_type", "owner");
                handleOptionClick("owner");
              }}
            >
              Sign in as Owner
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-center flex-column">
            <div style={{ width: "80%" }} className="d-flex flex-column">
              <label for="exampleInputEmail1" class="form-label labels">
                Email
              </label>
              {/* value={value} onChange={(e) => setValue(e.target.value)}  */}
              <input
                className="form-control py-3"
                style={{ fontSize: 12, fontWeight: "200" }}
                value={logins.email}
                onChange={(e) => handleChange("email", e.target.value)}
                placeholder="example@gmail.com"
              />
            </div>
            <div style={{ width: "80%" }} className="mt-4">
              <label for="exampleInputEmail1" class="form-label labels">
                Password
              </label>
              <div className="input-group">
                <input
                  placeholder="Enter Password"
                  value={logins.password}
                  onChange={(e) => handleChange("password", e.target.value)}
                  type={showPassword ? "text" : "password"}
                  style={{ fontSize: 12, fontWeight: "200" }}
                  className="form-control py-3"
                />
                <span
                  className="input-group-text"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </span>
              </div>
            </div>
            <div
              style={{ width: "80%" }}
              className="mt-2   d-flex justify-content-end"
            >
              <p className="forget mt-1 ">Forgot Password?</p>
            </div>
            <div
              style={{ width: "50%" }}
              className="my-3   d-flex justify-content-center"
            >{load? <Box sx={{ display: 'flex' }} className="justify-content-center">
            <CircularProgress />
          </Box>:
              <Button
                type="submit"
                style={{
                  height: "50px",
                  borderRadius: "10px",
                  marginTop: "40px",
                  marginBottom: "60px",
                }}
                fullWidth
                // className="my-4"
                variant="contained"
              >
                Sign In
              </Button>}
            </div>
            <div
              style={{ width: "50%", marginBottom: "60px" }}
              className="  d-flex justify-content-center align-items-center"
            >
              <hr
                className="mb-2"
                style={{
                  width: "100%",

                  margin: "auto",
                  borderTop: "1px solid grey",
                }}
              />
              <p
                className="mb-0 text-center orsign "
                style={{ width: "400px" }}
              >
                Or sign in with
              </p>
              <hr
                className="mb-2"
                style={{
                  width: "100%",
                  margin: "auto",
                  borderTop: "1px solid grey",
                }}
              />
            </div>

            <div
              style={{ width: "80%", gap: "25px" }}
              className="mt-1 my-4  d-flex justify-content-center"
            >
              <img src={img1} style={{ width: "70px", height: "70px" }} />
              <img src={img2} style={{ width: "70px", height: "70px" }} onClick={() => loginGoogle()}/>
              <img src={img3} style={{ width: "70px", height: "70px" }} />
            </div>
          </div>
        </form>
    
      </Modal.Body>
    </Modal>
  );
}
export default RegisterModal;
