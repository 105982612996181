import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import first from "../../assets/first.png";
import first1 from "../../assets/rec1.png";
import rev2 from "../../assets/rev2.png";
import rev3 from "../../assets/rev3.png";
import sign from "../../assets/sign.svg";
import { Avatar, Grid } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
const HappyUser = () => {
 
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const reviews = [
    {
      name: 'Sarah',
      text: "Fantastic service! GetMyCharter made our vacation unforgettable. The yacht we chartered was top-notch, and the crew went above and beyond to ensure our comfort. Highly recommended!",
      avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6J5f4KX5jB1REQMJ7LK52TdJfM6QNAJYwmQ&usqp=CAU',
      backgroundImage: first
    },
    {
      name: 'Michael',
      text: "Incredible experience with GetMyCharter! From booking to disembarking, everything was seamless. The staff was friendly and knowledgeable, and the boat exceeded our expectations. Can't wait to book with them again!",
      avatar: 'https://img.freepik.com/free-photo/happy-bearded-man-busines-clothes-with-crossed-arms-looking-camera-gray_171337-11335.jpg',
      backgroundImage: rev2
    },
    {
      name: 'Emily',
      text: "Absolutely loved our charter with GetMyCharter! The entire process was hassle-free, and the boat was in pristine condition. Our captain, John, was fantastic and showed us the best spots. Thanks for an amazing adventure!",
      avatar: 'https://media.istockphoto.com/id/1355854485/photo/satisfied-business-woman-okay-sign-approving-deal.jpg?s=612x612&w=0&k=20&c=dDGQRq_zm9IuIcyPSRWllG56ykcz8Rd4c_RBt3KXFQU=',
      backgroundImage: rev3
    },
    {
      name: 'John',
      text: "We had an incredible time with GetMyCharter! The yacht was beautiful and well-maintained, and the crew was professional and attentive. We'll definitely be booking with them again in the future!",
      avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfGEsGHlO-N1EI6YWRsTPzxOfd4XIQKXFANtL-4UDLQUNQ-vqBkQ80I4yj3t5FaLclh-w&usqp=CAU',
      backgroundImage: first
    },
    {
      name: 'Sophia',
      text: "Our experience with GetMyCharter was nothing short of amazing! The entire process, from booking to disembarking, was seamless. The boat was luxurious, and the crew made sure we had a fantastic time. Highly recommend!",
      avatar: 'https://img.freepik.com/free-photo/portrait-corporate-woman-holding-clipboard-work-standing-formal-outfit-white-background_1258-88411.jpg?size=626&ext=jpg&ga=GA1.1.1700460183.1708473600&semt=ais',
      backgroundImage: rev2
    },
    // Add more reviews here
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className=''>
    <Slider {...settings}>
      {reviews.map((review, index) => (
        <div key={index}>
          <Grid item xs={12} md={4} className=' d-flex justify-content-center'>
            <div className=' d-flex flex-column justify-content-center align-items-center' style={{
              // overflow: 'hidden',
              backgroundImage: `url(${review.backgroundImage})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              position: 'relative',
              height: '390px',
              width: '400px',
             marginTop:'5%'
            }}>
              <p className='w-50 text-center happyName'>{review.name}</p>
              <img src={sign} alt="Signature" />
              <p className='happyText text-center' style={{ width: "63%", textAlign:'justify'}}>{review.text}</p>
              <Avatar
                alt={review.name} // Set alt text dynamically
                src={review.avatar}
                sx={{ width: 73, height: 66, position: 'absolute', top: '15%', left: '55%', transform: 'translate(-50%, -50%)' }}
              />
            </div>
          </Grid>
        </div>
      ))}
    </Slider>
    </div>
  );
};

export default HappyUser;
