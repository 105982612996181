import { Grid } from "@mui/material";
import React from "react";
import "./style.css";
import img from "../../assets/card.png";
import {
  Rating,
  useMediaQuery,
  ThemeProvider,
  createTheme,
} from "@mui/material";
const FeedBack = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <p className="mb-0 include">Our Customer Feedback</p>
        <p className="mb-3 includeText my-3">
          Don’t take our word for it. Trust our customers
        </p>
      </Grid>
      <Grid item xs={12} mt={2} className=" w-100">
        <div className="d-flex   justify-content-between align-items-center w-100">
          <div className="d-flex" style={{ gap: "15px" }}>
            <img
              src={img}
              style={{ width: "50px", height: "50px", objectFit: "contain" }}
            />
            <div>
              <p className="mb-0 name">Shaheer Qamar</p>
              <p className="mb-0 date">November 2022</p>
            </div>
          </div>
          <Rating
            style={{ color: "#2D9CDB", fontSize: 16 }}
            name="no-value"
            value={3}
          />
        </div>
        <p className="mb-0 mt-2">
          Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
          sint. Velit officia consequat duis enim velit mollit. Exercitation
          veniam consequat sunt nostrud amet. Amet minim mollit non deserunt
          ullamco est sit aliqua dolor do amet sint. Velit officia consequat
          duis enim velit mollit. Exercitation veniam consequat sunt nostrud
          amet.
        </p>
      </Grid>
      <Grid item xs={12} mt={2} className=" w-100">
        <div className="d-flex   justify-content-between align-items-center w-100">
          <div className="d-flex" style={{ gap: "15px" }}>
            <img
              src={img}
              style={{ width: "50px", height: "50px", objectFit: "contain" }}
            />
            <div>
              <p className="mb-0 name">Shaheer Qamar</p>
              <p className="mb-0 date">November 2022</p>
            </div>
          </div>
          <Rating
            style={{ color: "#2D9CDB", fontSize: 16 }}
            name="no-value"
            value={3}
          />
        </div>
        <p className="mb-0 mt-2">
          Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
          sint. Velit officia consequat duis enim velit mollit. Exercitation
          veniam consequat sunt nostrud amet. Amet minim mollit non deserunt
          ullamco est sit aliqua dolor do amet sint. Velit officia consequat
          duis enim velit mollit. Exercitation veniam consequat sunt nostrud
          amet.
        </p>
      </Grid>
    </Grid>
  );
};

export default FeedBack;
