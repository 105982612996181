import { Button, Grid, Paper } from '@mui/material';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
function BookingModal(props) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    console.log(props.type)
    const navigate=useNavigate()
    const Booking=props.type
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Booking Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-3 d-flex justify-content-center' >
      <Grid item xs={3} className="px-5" style={{width:isSmallScreen?"100%":"70%"}} >
            <p className="chatItemTitle">Your request regarding the boat of {props.type.type.title}</p>
            <div className="d-flex " style={{border:"1px solid grey",borderRadius:"10px"}}>
                <img src={props.type.type.images[0]} style={{width:"190px",height:"90px",borderTopLeftRadius:"10px",borderBottomLeftRadius:"10px"}}/>
                <div className="d-flex  w-100 flex-column  justify-content-center align-items-center">
                    <p className="chatcardtitle mb-0">{props.type.type.title}</p>
                    <p className="chatcardsub text-center mb-0">2018 View the listing</p>
                    <p className="chatcardsub text-center mb-0">{props.type.type.harbour}</p>
                </div>
            </div>
            <div className="d-flex w-100 mt-3 justify-content-between align-items-center">
                <p className="listItemChat mb-0">Check- Out</p>
                <p className="listItemChat mb-0">{props?.type?.dates[props?.type?.dates.length-1]+"-"+props?.type?.check_out_time}</p>
            </div>
            <div className="d-flex w-100 mt-3 justify-content-between align-items-center">
                <p className="listItemChat mb-0">Number of Guest</p>
                <p className="listItemChat mb-0">25 People</p>
            </div>
            <div className="d-flex w-100 mt-3 justify-content-between align-items-center">
                <p className="listItemChat mb-0">Rental Price</p>
                <p className="listItemChat mb-0">{props?.type?.price.toFixed(2)} Aed</p>
            </div>
            <div className="d-flex w-100 mt-3 justify-content-between align-items-center">
                <p className="listItemChat mb-0">Vat 5%</p>
                <p className="listItemChat mb-0">{(props?.type?.price*0.05).toFixed(2) }Aed</p>
            </div>
            <div className="d-flex w-100 mt-3 justify-content-between align-items-center">
                <p className="listItemChat mb-0">Handing Fees</p>
                <p className="listItemChat mb-0">Free</p>
            </div>
            <div className="mt-3" style={{borderTop:"1px solid black"}}/>
            <div className="d-flex w-100 mt-1 justify-content-between align-items-center">
                <p className="listItemChat mb-0">Total</p>
                <p className="listItemChat mb-0" style={{color:"#246BBC"}}>{(props?.type?.price+props?.type.price*0.05).toFixed(2)} Aed</p>
            </div>
            <div className="d-flex w-100 mt-3 justify-content-center align-items-center">
              <Button variant="contained" style={{backgroundColor:"#246BBC",transform:"none",color:"white",width:"150px"}} onClick={()=>{navigate("/Confirmation" , { state: { Booking } })
            props.onHide()
            }}  >Book</Button>
            </div>

          </Grid>
      </Modal.Body>
      
    </Modal>
  );
}


export default BookingModal