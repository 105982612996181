import { Button, Grid } from '@mui/material'
import React from 'react'
import arw from "../../assets/arw.svg"
import google from "../../assets/google.svg"
import apple from "../../assets/apple.svg"
const Security = () => {
  return (
   <div className='container d-flex justify-content-center mt-5'>
    <Grid container style={{width:"40%"}}>
            <Grid item xs={12}  className=' d-flex justify-content-center'>
                <p className='mb-0 text3'>Security</p>
            </Grid>
            <Grid item xs={12}  className=' d-flex mt-4 '>
                <p className='mb-0 text4'>Password</p>
            </Grid>
            <Grid item xs={12}  className=' d-flex mt-2 justify-content-between '>
                <p className='mb-0 text5'>Change Password</p>
                <img src={arw}/>
            </Grid>
            <Grid item xs={12}  className=' d-flex mt-4 '>
                <p className='mb-0 text4'>Active Sessions</p>
            </Grid>
            <Grid item xs={12}  className=' d-flex mt-2 justify-content-between '>
                <p className='mb-0 text5'>Sign out from all the active sessions</p>
                <img src={arw}/>
            </Grid>
            <Grid item xs={12}  className=' d-flex mt-4 '>
                <p className='mb-0 text4'>Linked Accounts</p>
            </Grid>
            <Grid item xs={12}  className=' d-flex mt-2 justify-content-between '>
                <div className='d-flex align-items-center ' style={{gap:"15px"}}>
                <img src={google}/>
                <p className='mb-0 text5'>Google </p>
                </div>
                <img src={arw}/>
            </Grid>
            <Grid item xs={12}  className=' d-flex mt-2 justify-content-between '>
            <div className='d-flex align-items-center ' style={{gap:"15px"}}>
                <img src={apple} style={{marginLeft:-12}}/>
                <p className='mb-0 text5' style={{marginLeft:-12}}>Apple </p>
                </div>
                <img src={arw}/>
            </Grid>
            <Grid item xs={12} className='mt-4'>
                <Button variant='outlined' style={{fontSize:16,fontWeight:600}} fullWidth>Delete Account</Button>
            </Grid>
    </Grid>
   </div>
  )
}

export default Security