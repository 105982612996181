import { Button, CircularProgress, FormControlLabel, FormGroup, Grid, Radio } from '@mui/material'
import React, { useState } from 'react'
import apiClient from '../../Api/apiClient';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const Equipment = ({data:data1}) => {
    const data=[
     
        {
        cat:"Outdoor",
        sub:["Bimini","Outdoor shower","External table","External speakers","Teak deck","Bow sundeck","Aft sundeck","Bathing Platform","Bathing ladder"]
    },
        {
        cat:"Navigation",
        sub:["Dinghy","Dinghy's motor","Bow thruster","Electric windlass","Autopilot","GPS","Depth sounder","VHF","Satellite phone","Guides & Maps"]
    },
        {
        cat:"Water sports",
        sub:["Water skis","Monoski","Wakeboard","Towable Tube","Inflatable banana","Kneeboard"]
    },
        {
        cat:"Extra Comforts",
        sub:["Hot water","Watermaker","Air Conditioning","Fans","Heating","Electric toilet","Bed linen","Bath towels","Beach towels","Wi-Fi","USB socket","TV"]
    },
        {
        cat:"Leisure activities",
        sub:["Paddle board","Kayak","Snorkelling equipment","Fishing equipment","Diving equipment","Seabob","Bike","Electric scooter","Drone","Video camera"]
    },
        {
        cat:"Kitchen",
        sub:["Fridge","Freezer","Oven/stovetop","BBQ grill","Microwave","Coffee machine","Ice machine","Ice box"]
    },
        {
        cat:"Onboard energy",
        sub:["Generator","Power inverter","220V power outlet"]
    },
]
const[load,setLoad]=useState(false)
const navigation =useNavigate()
    const [selectedSubcategories, setSelectedSubcategories] = useState({});
  
    const handleSubcategoryChange = (category, subcategory) => {
      setSelectedSubcategories((prevSelected) => ({
        ...prevSelected,
        [category]: [...(prevSelected[category] || []), subcategory]
      }));
    };
    const [transformedData, setTransformedData] = useState({
        yatchId: data1||"",
        progress: "100",
        equipments_outdoor: [],
        equipments_navigation: [],
        equipments_kitchen: [],
        equipments_onboard_energy: [],
        equipments_water_sports: [],
        equipments_leisure_activities: [],
        equipments_extra_comforts: [],
      });
      const categoryMapping = {
    "Outdoor": "equipments_outdoor",
    "Navigation": "equipments_navigation",
    "Water sports": "equipments_water_sports",
    "Leisure activities": "equipments_leisure_activities",
    "Extra Comforts": "equipments_extra_comforts",
    "Kitchen": "equipments_kitchen",
    "Onboard energy": "equipments_onboard_energy",
    "Navigation": "equipments_navigation"
  };
      const transformData = () => {
        const newData = { ...transformedData };
    
        for (const category in selectedSubcategories) {
          if (categoryMapping.hasOwnProperty(category)) {
            const newKey = categoryMapping[category];
            newData[newKey] = Array.from(new Set(selectedSubcategories[category])); // Removing duplicates using Set
          }
        }
    
        setTransformedData(newData);
        return newData
      };
const handleSubmit=async(e)=>{
    e.preventDefault()
    
    const data=transformData()
    console.log(data,"llllll")
    const allArraysFilled = Object.values(transformedData).every(
        (value) => Array.isArray(value) && value.length > 0
      );
  
console.log(allArraysFilled)
setLoad(true)
    const result =await apiClient.post("/yatchs/create/fifth-screen",data)
    setLoad(false)
    if(result.error) return  toast.error("Error")
toast.success("Successfully Submit")
navigation("/")
   
}
  return (
    <>
        <form onSubmit={handleSubmit}>
    <Grid container className='px-3 mt-2'>
        {data?.map((item)=>
        <>
        <Grid item xs={12} md={4}>
        <p className='cal'>{item.cat}</p>
        {item.sub?.map((sub)=>
         <Grid item xs={12}>
            {/* <FormControlLabel className='mb-0 sub' value={item} control={<Radio />} label={item} /> */}
            <FormControlLabel 
                  className='mb-0 sub'
                  value={sub}
                  control={<Radio />}
                  label={sub}
                  onChange={() => handleSubcategoryChange(item.cat, sub)}
                />
        </Grid>
        )}
         </Grid>
        </>
        )}
        <Grid item xs={12} className='d-flex justify-content-center'>
        {load?<CircularProgress />:
            <Button variant="contained" type="submit" >Save</Button>}
        </Grid>
    </Grid>
        </form>
    
    </>
  )
}

export default Equipment