import React, { useEffect } from 'react'
import "./style.css";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import BookingCard from './BookingCard';
import apiClient from '../../Api/apiClient';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
function CustomTabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const Booking = () => {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [value, setValue] = React.useState(0);
    const [bookings, setBooking] = React.useState();

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const fetchData=async()=>{
      const result=await apiClient.get("/bookings/renter")
      if (result.error) return
      setBooking(result.data.bookings)
      console.log(result)
    }

    useEffect(()=>{
      fetchData()
    },[])
  
  return (
   <>
   <div className={`container-fluid ${isSmallScreen?'p-1':'p-5'}`}>
   <p className='headBooking'>Your bookings</p>
   <Box sx={{ width: '100%' }} className="mt-4 ">
   
      <Box  >
        <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ style: {height:"4px",borderTopLeftRadius:"40px",borderTopRightRadius:"40px",overflowX:"scroll" } }} aria-label="basic tabs example">
          <Tab className={value==0?'tabActive':'tabstext'} label="Upcoming" {...a11yProps(0)} />
          <Tab className={value==1?'tabActive':'tabstext'} label="Completed" {...a11yProps(1)} />
          <Tab className={value==2?'tabActive':'tabstext'} label="Cancelled" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Grid container className='' spacing={2} >
          {bookings?.map((item)=>
          <Grid item xs={12} md={6} lg={4} className='d-flex justify-content-center'>
       <BookingCard item={item} type={"upcoming"}/>
       </Grid>
       )}
       {/* <BookingCard type={"upcoming"}/>
       <BookingCard type={"upcoming"}/> */}
       </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <div className='d-flex  ' style={{gap:"30px"}}>
     <p style={{fontWeight:"22px",fontWeight:"600"}}>No Yatch Available</p>
      {/* <BookingCard type={"complete"}/>
      <BookingCard type={"complete"}/>
      <BookingCard type={"complete"}/> */}
      </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      <div className='d-flex  ' style={{gap:"30px"}}>
      <p style={{fontWeight:"22px",fontWeight:"600"}}>No Yatch Available</p>
      </div>
      </CustomTabPanel>
    </Box>
   </div>
   </>
  )
}

export default Booking