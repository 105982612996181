import React from 'react'
import thick from "../../assets/thick.svg"
import { Avatar, Button, Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material'
const Profiles = () => {
  return (
    <>
    <div className='container'>
    <p className='favGead'>Complete your profile</p>
    <div className='d-flex aa  ' style={{gap:"40px",overflowX:"scroll",scrollbarWidth:'none'}}>
    <p className='listText'>Verification</p>
    <p className='listText'>Information</p>
    <p className='listText white_space'>Boating experience level</p>
    <p className='listText white_space'>Payment method</p>
    <p className='listText'>Password</p>
    </div>
    <div className='p-3' style={{border:"1px solid grey",borderRadius:"20px"}}>
    <p className='favGead' style={{fontSize:20}}>Complete your profile</p>
    <Grid container className='w-75'>
        <Grid item xs={12} md={6}>
        <div className='d-flex flex-column align-items-center'>
    <img src={thick} style={{width:"25px",height:"25px"}} className='mb-3'/>
    <p className='listText '>Your email address</p>
    <p className='' style={{color:"#246BBC",fontWeight:600 ,fontSize:14}}>Item Checked</p>
    </div>
        </Grid>
        <Grid item xs={12} md={6}>
        <div className='d-flex flex-column align-items-center'>
    <img src={thick} style={{width:"25px",height:"25px"}} className='mb-3'/>
    <p className='listText '>Your sailing CV</p>
    <p className='p-1' style={{border:"1px solid grey",borderRadius:"4px",color:"#246BBC",fontWeight:600 ,fontSize:14}}>Complete my nautical CV</p>
    </div>
        </Grid>
    </Grid>
    
    </div>



    <div className='p-3 mt-4' style={{border:"1px solid grey",borderRadius:"20px"}}>
    <p className='favGead' style={{fontSize:20}}>Personal information</p>
    
    <Grid item xs={2} className=' '>
        <div className='d-flex justify-content-center'>
            <div className='d-flex justify-content-center align-items-center flex-column'>
        <Avatar
className='text-center'
  sx={{ width: 150, height: 150 }}
/>
<p className='mb-0 text-center' style={{fontWeight:600}}>Change your profile</p>
<p>Minimum size: 260px x 260px</p>
</div>
</div>
<Button variant='contained' style={{textTransform: "capitalize",}} fullWidth> Select an image</Button>
        </Grid>
        <Grid container className='mt-3'>
        <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">First Name</label>
    <input    class="form-control  py-2" style={{width:"85%"}} aria-describedby="emailHelp"/>
          </Grid>
        <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">Surname</label>
    <input    class="form-control  py-2" style={{width:"85%"}} aria-describedby="emailHelp"/>
          </Grid>
        <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">Email</label>
    <input    class="form-control  py-2" style={{width:"85%"}} aria-describedby="emailHelp"/>
          </Grid>
          <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">Gender</label>
          <div>
          <select  class="form-select py-2 " aria-label="Default select example" style={{borderRadius:"5px",border:"1px solid #BFC7D2",width:"85%"}}>
  <option selected>select</option>
  
  <option >Male</option>
  <option >Male</option>
  <option >Male</option>
 
 
</select>
</div>
          </Grid>
          <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">Date of Birth</label>
    <input    class="form-control  py-2" style={{width:"85%"}} aria-describedby="emailHelp"/>
          </Grid>
          <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">Language</label>
          <div>
          <select  class="form-select py-2 " aria-label="Default select example" style={{borderRadius:"5px",border:"1px solid #BFC7D2",width:"85%"}}>
  <option selected>select</option>
  
  <option >English</option>
  <option >Urdu</option>
  <option >Arabic</option>
 
 
</select>
</div>
          </Grid>
          <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">Telephone</label>
    <input    class="form-control  py-2" style={{width:"85%"}} aria-describedby="emailHelp"/>
          </Grid>
          <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">Address</label>
    <input    class="form-control  py-2" style={{width:"85%"}} aria-describedby="emailHelp"/>
          </Grid>
          <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">Postal code</label>
    <input    class="form-control  py-2" style={{width:"85%"}} aria-describedby="emailHelp"/>
          </Grid>
          <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">City</label>
    <input    class="form-control  py-2" style={{width:"85%"}} aria-describedby="emailHelp"/>
          </Grid>
        <Grid item xs={12} >
        <p className='favGead mt-2' style={{fontSize:20}}>Company </p>
        </Grid>
        <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">Company</label>
    <input    class="form-control  py-2" style={{width:"85%"}} aria-describedby="emailHelp"/>
          </Grid>
          <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">Address</label>
    <input    class="form-control  py-2" style={{width:"85%"}} aria-describedby="emailHelp"/>
          </Grid>
        <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">Add VAT</label>
    <input    class="form-control  py-2" style={{width:"85%"}} aria-describedby="emailHelp"/>
          </Grid>
          <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">Website</label>
    <input    class="form-control  py-2" style={{width:"85%"}} aria-describedby="emailHelp"/>
          </Grid>
        <Grid item xs={12}  className='mt-2 d-flex justify-content-end' >
            <Button variant='contained'>Save</Button>
        </Grid>
        </Grid>
</div>

<div className='p-3 mt-4' style={{border:"1px solid grey",borderRadius:"20px"}}>
    <p className='favGead' style={{fontSize:20}}>Your nautical level</p>
   

<Grid container spacing={3}>

    <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">Your Level?</label>
          <div>
          <select  class="form-select py-2 " aria-label="Default select example" style={{borderRadius:"5px",border:"1px solid #BFC7D2",width:"85%"}}>
  <option selected>select</option>
  
  <option >1</option>
  <option >2</option>
  <option >3</option>
 
 
</select>
</div>
          </Grid>

    <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">You prefer?</label>
          <div>
          <select  class="form-select py-2 " aria-label="Default select example" style={{borderRadius:"5px",border:"1px solid #BFC7D2",width:"85%"}}>
  <option selected>select</option>
  
  <option >1</option>
  <option >2</option>
  <option >3</option>
 
 
</select>
</div>
          </Grid>

          <Grid item xs={12} md={4} mt={2}>
          <label for="exampleInputEmail1" class="form-label labels">Holder of a yacht licence
</label>
          <FormGroup>
          <FormControlLabel
        className='mb-0'
        control={<Checkbox />}
        label={<span style={{ color:'grey',fontWeight:"600" }}>Coastal license</span>} // Change color here
      />
          <FormControlLabel
        className='mb-0'
        control={<Checkbox />}
        label={<span style={{ color:'grey',fontWeight:"600" }}>Offshore license
        </span>} // Change color here
      />
          <FormControlLabel
        className='mb-0'
        control={<Checkbox />}
        label={<span style={{ color:'grey',fontWeight:"600" }}>Inland license
        </span>} // Change color here
      />
 
</FormGroup>
          </Grid>
          <Grid item xs={12} md={4} mt={2}>
          <label for="exampleInputEmail1" class="form-label labels">Other
</label>
          <FormGroup>
          <FormControlLabel
        className='mb-0'
        control={<Checkbox />}
        label={<span style={{ color:'grey',fontWeight:"600" }}>Coastal license</span>} // Change color here
      />
          <FormControlLabel
        className='mb-0'
        control={<Checkbox />}
        label={<span style={{ color:'grey',fontWeight:"600" }}>Offshore license
        </span>} // Change color here
      />
          <FormControlLabel
        className='mb-0'
        control={<Checkbox />}
        label={<span style={{ color:'grey',fontWeight:"600" }}>Inland license
        </span>} // Change color here
      />
 
</FormGroup>
          </Grid>
          <Grid item xs={12} md={4} mt={2}>
          <label for="exampleInputEmail1" class="form-label labels">Sailing experience
</label>
          <FormGroup>
          <FormControlLabel
        className='mb-0'
        control={<Checkbox />}
        label={<span style={{ color:'grey',fontWeight:"600" }}>Coastal license</span>} // Change color here
      />
          <FormControlLabel
        className='mb-0'
        control={<Checkbox />}
        label={<span style={{ color:'grey',fontWeight:"600" }}>Offshore license
        </span>} // Change color here
      />
          <FormControlLabel
        className='mb-0'
        control={<Checkbox />}
        label={<span style={{ color:'grey',fontWeight:"600" }}>Inland license
        </span>} // Change color here
      />
 
</FormGroup>
          </Grid>
          

          <Grid item xs={12} >
          <label for="exampleInputEmail1" class="form-label labels">Description</label>
          </Grid>
          <Grid item xs={12} md={6}>
          <textarea placeholder='Provide full details about yourself and your experience.' className='w-100' rows={5} style={{borderRadius:10,height:"250px"}}/>
          </Grid>
          <Grid item xs={12} md={6}>
          <div className='aa' style={{scrollbarWidth:"none",borderRadius:10,border:"1px solid grey",height:"250px",overflowY:"scroll",backgroundColor:"#E2F2FC"}}>
            <p className='px-4'>
            <span style={{fontWeight:"600"}}>Briefly describe yourself</span><br/>
Your age, your hobbies, your job, your region<br/>
Are you more of a motorboat or a sailboat type of person?
Explain what you like about sailing (fishing, water skiing, regattas, etc.)<br/>
How long have you been sailing for?
Briefly explain your sailing background and your various experiences as a renter or owner, crew member or skipper<br/>
Others
Your fears, your projects (regattas, crossings, travels, buying, maintenance, etc.)
            </p>
            </div>
          </Grid>


<Grid item xs={12} className='d-flex justify-content-end'>
    <Button variant='contained'>Save</Button>
</Grid>


          </Grid>
          </div>



         <div className='p-3 mt-4' style={{border:"1px solid grey",borderRadius:"20px"}}>
    <p className='favGead' style={{fontSize:20}}>Payment settings</p>
   

<Grid container spacing={3}>

    <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">Down payment percentage wanted</label>
          <div>
          <select  class="form-select py-2 " aria-label="Default select example" style={{borderRadius:"5px",border:"1px solid #BFC7D2",width:"85%"}}>
  <option selected>select</option>
  
  <option >1</option>
  <option >2</option>
  <option >3</option>
 
 
</select>
</div>
          </Grid>

    <Grid item xs={12} md={6}>
          <label for="exampleInputEmail1" class="form-label labels">Number of days before balance payment</label>
          <div>
          <select  class="form-select py-2 " aria-label="Default select example" style={{borderRadius:"5px",border:"1px solid #BFC7D2",width:"85%"}}>
  <option selected>select</option>
  
  <option >1</option>
  <option >2</option>
  <option >3</option>
 
 
</select>
</div>
          </Grid>

          <Grid item xs={12} className='d-flex justify-content-end'>
    <Button variant='contained'>Save</Button>
</Grid>
          </Grid>
          </div>

          <div className='p-3 mt-4' style={{border:"1px solid grey",borderRadius:"20px"}}>
    <p className='favGead' style={{fontSize:20}}>Change your password</p>
   

<Grid container spacing={3}>
<Grid item xs={12} md={4}>
          <label for="exampleInputEmail1" class="form-label labels">Old password</label>
    <input    class="form-control  py-2" style={{width:"85%"}} aria-describedby="emailHelp"/>
          </Grid>
<Grid item xs={12} md={4}>
          <label for="exampleInputEmail1" class="form-label labels">New password</label>
    <input    class="form-control  py-2" style={{width:"85%"}} aria-describedby="emailHelp"/>
          </Grid>
<Grid item xs={12} md={4}>
          <label for="exampleInputEmail1" class="form-label labels">Re-enter password</label>
    <input    class="form-control  py-2" style={{width:"85%"}} aria-describedby="emailHelp"/>
          </Grid>
          <Grid item xs={12} className='d-flex justify-content-end'>
            <div className='d-flex' style={{gap:"15px"}}>

    <Button variant='outlined' style={{textTransform: "capitalize",color:"grey",borderColor:"grey"}}>Forgot your password?</Button>
    <Button variant='contained'>Save</Button>
            </div>
</Grid>
</Grid>
</div>


<div className='p-3 mt-4' style={{border:"1px solid grey",borderRadius:"20px"}}>
    <p className='favGead' style={{fontSize:20}}>Settings</p>
   <Grid container>
    <Grid item xs={12}>
    <FormGroup>
  <FormControlLabel control={<Checkbox defaultChecked />}  label={<span style={{ fontWeight:"600" }}>Receive a text when you have a new message</span>} />

</FormGroup>
    </Grid>
    <Grid item xs={12} className='d-flex justify-content-end'>
            {/* <div className='d-flex' style={{gap:"15px"}}> */}

    {/* <Button variant='outlined' style={{textTransform: "capitalize",color:"grey",borderColor:"grey"}}>Forgot your password?</Button> */}
    <Button variant='contained'>Save</Button>
            {/* </div> */}
</Grid>
   </Grid>
</div>



    </div>
    </>
  )
}

export default Profiles